// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/home/runner/work/basement/basement/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from 'components/Loader/Loader';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__index' */'@/layouts/index.js'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/404",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'@/pages/404.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/apps/bookings/BookingForm",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__apps__bookings__BookingForm' */'@/pages/apps/bookings/BookingForm.js'), loading: LoadingComponent})
      },
      {
        "path": "/apps/bookings/OwnerModal",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__apps__bookings__OwnerModal' */'@/pages/apps/bookings/OwnerModal.js'), loading: LoadingComponent})
      },
      {
        "path": "/apps/bookings/PayModal",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__apps__bookings__PayModal' */'@/pages/apps/bookings/PayModal.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/apps/bookings/Payment",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__apps__bookings__Payment' */'@/pages/apps/bookings/Payment.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/apps/bookings",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__apps__bookings__index' */'@/pages/apps/bookings/index.js'), loading: LoadingComponent})
      },
      {
        "path": "/apps",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__apps__index' */'@/pages/apps/index.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/apps/notices",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__apps__notices' */'@/pages/apps/notices.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/apps/venues/VenueDrawer",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__apps__venues__VenueDrawer' */'@/pages/apps/venues/VenueDrawer.js'), loading: LoadingComponent})
      },
      {
        "path": "/apps/venues",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__apps__venues__index' */'@/pages/apps/venues/index.js'), loading: LoadingComponent})
      },
      {
        "path": "/assets",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__assets__index' */'@/pages/assets/index.js'), loading: LoadingComponent})
      },
      {
        "path": "/contractors",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__contractors__index' */'@/pages/contractors/index.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/dashboard",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__dashboard__index' */'@/pages/dashboard/index.js'), loading: LoadingComponent}),
        "propTypes": {}
      },
      {
        "path": "/facilities",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__facilities__index' */'@/pages/facilities/index.js'), loading: LoadingComponent})
      },
      {
        "path": "/",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__index' */'@/pages/index.js'), loading: LoadingComponent}),
        "title": "Basementgrid"
      },
      {
        "path": "/login",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__login__index' */'@/pages/login/index.js'), loading: LoadingComponent}),
        "propTypes": {}
      },
      {
        "path": "/occupants/AccessCard",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__occupants__AccessCard' */'@/pages/occupants/AccessCard.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/occupants/Drawer",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__occupants__Drawer__index' */'@/pages/occupants/Drawer/index.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/occupants",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__occupants__index' */'@/pages/occupants/index.js'), loading: LoadingComponent})
      },
      {
        "path": "/occupants/vehicleModal",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__occupants__vehicleModal' */'@/pages/occupants/vehicleModal.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/payment",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__payment__index' */'@/pages/payment/index.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/receipt",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__receipt__index' */'@/pages/receipt/index.js'), loading: LoadingComponent})
      },
      {
        "path": "/register",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__register__index' */'@/pages/register/index.js'), loading: LoadingComponent})
      },
      {
        "path": "/resetPassword",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__resetPassword__index' */'@/pages/resetPassword/index.js'), loading: LoadingComponent})
      },
      {
        "path": "/team-member",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__team-member__index' */'@/pages/team-member/index.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/units/OwnerModal",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__units__OwnerModal' */'@/pages/units/OwnerModal.js'), loading: LoadingComponent})
      },
      {
        "path": "/units/UnitForm",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__units__UnitForm' */'@/pages/units/UnitForm.jsx'), loading: LoadingComponent})
      },
      {
        "path": "/units",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__units__index' */'@/pages/units/index.js'), loading: LoadingComponent})
      },
      {
        "path": "/user",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__index' */'@/pages/user/index.js'), loading: LoadingComponent}),
        "propTypes": {}
      },
      {
        "path": "/user/:id",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__id__index' */'@/pages/user/[id]/index.js'), loading: LoadingComponent}),
        "propTypes": {}
      },
      {
        "path": "/work-orders",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__work-orders__index' */'@/pages/work-orders/index.tsx'), loading: LoadingComponent})
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'@/pages/404.tsx'), loading: LoadingComponent})
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
