import { cloneDeep } from "lodash"
import moment from "moment"
import "moment/locale/zh-cn"
import store from "store"
import { i18n } from "./config"
import { WEEKShow } from "./constant"
import { pathToRegexp } from "path-to-regexp"

export classnames from "classnames"
export config from "./config"
export request from "./request"
export { Color } from "./theme"
export const getName = (name = "") => {
	name += ""
	return name
		.toUpperCase()
		.split(" ")
		.map(i => i.slice(0, 1))
		.join(" ")
}
export const languages = i18n ? i18n.languages.map(item => item.key) : []
export const defaultLanguage = i18n ? i18n.defaultLanguage : ""

/**
 * Query objects that specify keys and values in an array where all values are objects.
 * @param   {array}         array   An array where all values are objects, like [{key:1},{key:2}].
 * @param   {string}        key     The key of the object that needs to be queried.
 * @param   {string}        value   The value of the object that needs to be queried.
 * @return  {object|undefined}   Return frist object when query success.
 */
export function queryArray(array, key, value) {
	if (!Array.isArray(array)) {
		return
	}
	return array.find(_ => _[key] === value)
}

/**
 * Convert an array to a tree-structured array.
 * @param   {array}     array     The Array need to Converted.
 * @param   {string}    id        The alias of the unique ID of the object in the array.
 * @param   {string}    parentId       The alias of the parent ID of the object in the array.
 * @param   {string}    children  The alias of children of the object in the array.
 * @return  {array}    Return a tree-structured array.
 */
export function arrayToTree(
	array,
	id = "id",
	parentId = "pid",
	children = "children",
) {
	const result = []
	const hash = {}
	const data = cloneDeep(array)

	data.forEach((item, index) => {
		hash[data[index][id]] = data[index]
	})

	data.forEach(item => {
		const hashParent = hash[item[parentId]]
		if (hashParent) {
			!hashParent[children] && (hashParent[children] = [])
			hashParent[children].push(item)
		} else {
			result.push(item)
		}
	})
	return result
}
export const showOpeningHours = (record = {}) => {
	const { weekMax, weekMin, minTime, maxTime } = record
	if (
		weekMax !== null &&
		weekMin !== null &&
		minTime !== null &&
		maxTime !== null
	) {
		const s = timeShow(minTime)
		const e = timeShow(maxTime)
		return `${WEEKShow[weekMin]}${" "}-${" "}${
			WEEKShow[weekMax]
		}:${" "}${s}-${e}`
	}
	return "-"
}
export const timeShow = time => {
	const hour =
		moment.duration(time || 0).hours() < 10
			? "0" + moment.duration(time || 0).hours()
			: moment.duration(time || 0).hours()
	const min =
		moment.duration(time || 0).minutes() < 10
			? "0" + moment.duration(time || 0).minutes()
			: moment.duration(time || 0).minutes()
	return `${hour}:${min}`
}
export const showDate = (
	time,
	language = "en",
	format = "MMM D, YYYY, HH:mm",
) =>
	moment(time)
		.locale(language)
		.format(format)
export const insertStr = (soure, start, newStr) =>
	soure.slice(0, start) + newStr + soure.slice(start)
export const moneyShow = (number, dollarType = "$", floatNumber = 2) => {
	const head = Math.floor(Number(number || 0) / 10 ** floatNumber)
	const tail = Math.floor(Number(number || 0) % 10 ** floatNumber) //remove后面两位小数
	// const tail = +tailStr.replace(/\./g, "")
	const nList = [...`${head}`],
		list = []
	for (let i = nList.length - 1, j = 0; i >= 0; i--, j++) {
		if (j % 3 === 0 && j !== 0) {
			list.unshift(",")
		}
		list.unshift(nList[i])
	}
	const tList = [".", `${tail >= 10 ? tail : "0" + tail}`]
	list.push(...tList)
	const money = list.join("")
	return `${dollarType}${money}`
}
export const transTimeToHourMoment = time =>
	moment.duration(time).hours() - 0 || 0
export const transTimeToDayMoment = time =>
	(moment.duration(time).days() - 0) / 24 || 0

export const transTimeToHour = (time = 0) => (time - 0) / (1000 * 60 * 60) || 0
export const transTimeToDay = (time = 0) =>
	(time - 0) / (1000 * 60 * 60 * 24) || 0
export const TimelineItem = (i = 0) => {
	const startHour = moment.duration(i).hours() || 0
	const startMin = moment.duration(i).minutes() || 0

	// const end = moment.duration(i.slot_end).hours() || ""
	return `${startHour < 10 ? "0" + startHour : startHour}:${
		startMin < 10 ? "0" + startMin : startMin
	}`
}
/**
 * In an array object, traverse all parent IDs based on the value of an object.
 * @param   {array}     array     The Array need to Converted.
 * @param   {string}    current   Specify the value of the object that needs to be queried.
 * @param   {string}    parentId  The alias of the parent ID of the object in the array.
 * @param   {string}    id        The alias of the unique ID of the object in the array.
 * @return  {array}    Return a key array.
 */
export function queryPathKeys(array, current, parentId, id = "id") {
	const result = [current]
	const hashMap = new Map()
	array.forEach(item => hashMap.set(item[id], item))

	const getPath = current => {
		const currentParentId = hashMap.get(current)[parentId]
		if (currentParentId) {
			result.push(currentParentId)
			getPath(currentParentId)
		}
	}

	getPath(current)
	return result
}

/**
 * In an array of objects, specify an object that traverses the objects whose parent ID matches.
 * @param   {array}     array     The Array need to Converted.
 * @param   {string}    current   Specify the object that needs to be queried.
 * @param   {string}    parentId  The alias of the parent ID of the object in the array.
 * @param   {string}    id        The alias of the unique ID of the object in the array.
 * @return  {array}    Return a key array.
 */
export function queryAncestors(array, current, parentId, id = "id") {
	const result = [current]
	const hashMap = new Map()
	array.forEach(item => hashMap.set(item[id], item))

	const getPath = current => {
		const currentParentId = hashMap.get(current[id])[parentId]
		if (currentParentId) {
			result.push(hashMap.get(currentParentId))
			getPath(hashMap.get(currentParentId))
		}
	}

	getPath(current)
	return result
}
export const getLink = (str, type) => {
	let string = str
	if (typeof str === "string") {
		string = str
			.split(" ")
			.map(s => {
				if (s.startsWith("http://") || s.startsWith("https://")) {
					return `<a target='_blank' href=${s}>${s}</a>`
				}
				return s
			})
			.join(" ")
	}
	return type === "string" ? (
		string
	) : (
		<div dangerouslySetInnerHTML={{ __html: string }}></div>
	)
}
/**
 * Query which layout should be used for the current path based on the configuration.
 * @param   {layouts}     layouts   Layout configuration.
 * @param   {pathname}    pathname  Path name to be queried.
 * @return  {string}   Return frist object when query success.
 */
export function queryLayout(layouts, pathname) {
	let result = "public"

	const isMatch = regepx => {
		return regepx instanceof RegExp
			? regepx.test(pathname)
			: pathToRegexp(regepx).exec(pathname)
	}

	for (const item of layouts) {
		let include = false
		let exclude = false
		if (item.include) {
			for (const regepx of item.include) {
				if (isMatch(regepx)) {
					include = true
					break
				}
			}
		}

		if (include && item.exclude) {
			for (const regepx of item.exclude) {
				if (isMatch(regepx)) {
					exclude = true
					break
				}
			}
		}

		if (include && !exclude) {
			result = item.name
			break
		}
	}

	return result
}

export function getLocale() {
	return store.get("locale") || defaultLanguage
}

export function setLocale(language) {
	if (getLocale() !== language) {
		moment.locale(language === "zh" ? "zh-cn" : language)
		store.set("locale", language)
		window.location.reload()
	}
}

export const getNameFormat = item => {
	if (!item.legal_name && !item.name) {
		console.warn("this is dirty name!", item)
		return undefined
	}
	return `${item.legal_name || "-"}`
	// return `${item.name || "-"}(${item.legal_name || "-"})`
}
export const getHourFromSecond = time => time / 365 / 24 / 60 / 60
export const getSecondFromHour = time => time * 365 * 24 * 60 * 60
