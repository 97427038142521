/*
 * @Author: your name
 * @Date: 2021-06-07 17:56:29
 * @LastEditTime: 2021-06-20 22:28:53
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \basement\basementWeb\src\pages\payment\components\Drawer.tsx
 */
import React from "react"
import { Drawer } from "@/components"
import { DrawerProps, Tabs } from "antd"
import styles from "./index.less"
import DetailItem from "./DetailItem"
interface PaymentDrawerProps extends DrawerProps {
	modal?: any
	selectedRow?: POJO
}
const { TabPane } = Tabs
export default function PaymentDrawer(props: PaymentDrawerProps) {
	const { modal, selectedRow = {} } = props
	const [visible, setVisible] = modal
	const { pay_now: paymentDetail } = selectedRow
	return (
		<Drawer
			maskClosable
			visible={visible}
			onClose={() => {
				setVisible(false)
			}}
			bodyStyle={{ padding: 0 }}
		>
			<div style={{ padding: "24px" }}>payment Detail</div>

			<div className={styles.tabs}>
				<Tabs
					animated={false}
					defaultActiveKey="1"
					// onChange={key => console.log(key)}
				>
					<TabPane tab="DETAILS" key="1">
						<DetailItem record={selectedRow} />
					</TabPane>
					<TabPane tab="TIMELINE" key="2">
						Content of Tab Pane 2
					</TabPane>
				</Tabs>
			</div>
		</Drawer>
	)
}
