/*
 * @Author: your name
 * @Date: 2020-09-11 09:39:01
 * @LastEditTime: 2021-07-22 18:34:02
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \basementWeb\src\pages\user\[id]\models\detail.js
 */
import api from "api"
import { pathToRegexp } from "path-to-regexp"

const { queryUser } = api

export default {
	namespace: "userDetail",

	state: {
		data: {},
	},

	subscriptions: {
		setup({ dispatch, history }) {
			history.listen(({ pathname }) => {
				const match = pathToRegexp("/user/:id").exec(pathname)
				if (match) {
					dispatch({ type: "query", payload: { id: match[1] } })
				}
			})
		},
	},

	effects: {
		*query({ payload }, { call, put }) {
			const data = yield call(queryUser, payload)
			const { success, message, status, ...other } = data
			if (success) {
				yield put({
					type: "querySuccess",
					payload: {
						data: other,
					},
				})
			} else {
				throw data
			}
		},
	},

	reducers: {
		querySuccess(state, { payload }) {
			const { data = {} } = payload
			return {
				...state,
				data,
			}
		},
	},
}
