import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import styles from "./Loader.less"

const Loader = ({ spinning = false, fullScreen, loadingTip, opacity }) => {
	return (
		<div
			className={classNames(styles.loader, {
				[styles.hidden]: !spinning,
				[styles.fullScreen]: fullScreen,
			})}
			style={
				opacity
					? {
							opacity,
					  }
					: {}
			}
		>
			<div className={styles.warpper}>
				<div className={styles.inner} />
				<div className={styles.text}>{loadingTip ? loadingTip : `LOADING`}</div>
			</div>
		</div>
	)
}

Loader.propTypes = {
	spinning: PropTypes.bool,
	fullScreen: PropTypes.bool,
}

export default Loader
