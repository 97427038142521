/*
 * @Author: your name
 * @Date: 2020-09-18 22:07:20
 * @LastEditTime: 2021-07-22 18:31:16
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /basement/basementWeb/src/pages/tenants/model.js
 */
import { parse } from "qs"
// import modelExtend from 'dva-model-extend'
import api from "api"
import modelExtend from "dva-model-extend"
import { model } from "utils/model"
import store from "store"
import { ROLE_TYPE } from "utils/constant"
import { message } from "antd"
import { pathToRegexp } from "path-to-regexp"

const {
	linkRecord,
	sendEmail,
	delEmail,
	getEstate,
	editTenant,
	editSelfAPI,
	getUnit,
	addVehi,
	editVehi,
	delVehi,
	vehicleApi,
	accessApi,
	addAcc,
	delAcc,
} = api

export default modelExtend(model, {
	namespace: "tenants",
	state: {
		tableList: [],
		unitList: [],
		vehicelList: [],
	},
	subscriptions: {
		setup({ dispatch, history }) {
			history.listen(({ pathname, query }) => {
				if (pathToRegexp("/occupants").exec(pathname)) {
					dispatch({
						type: "updateState",
						payload: { self: undefined, add: undefined },
					})
					dispatch({ type: "app/getEstate" }).then(() => {
						dispatch({ type: "query" })
					})
					if (query.self) {
						dispatch({ type: "updateState", payload: { self: true } })
						window.history.replaceState(null, null, "/occupants")
					}
					if (query.add) {
						dispatch({ type: "updateState", payload: { add: true } })
						window.history.replaceState(null, null, "/occupants")
					}
				}
			})
		},
	},
	effects: {
		*queryUnit({ payload }, { call, put, select }) {
			const { selectedEstate = {} } = yield select(_ => _.app) || {}
			const params = { estate_id: selectedEstate.estate_id }

			const { estate_id } = params
			const result = yield call(getUnit, {
				limit: -1,
				from: 0,
				estate_id,
				facility_id: -1,
				order_by: "name",
				asc: 2,
			}) || {}
			const data = (result || {}).data
			if (data) {
				yield put({
					type: "updateState",
					payload: { unitList: data.list, total: data.total },
				})
			}
		},
		*vehicle({ payload }, { call, put }) {
			const res = yield call(vehicleApi, payload)
			if (res && res.data) {
				yield put({
					type: "updateState",
					payload: { vehicelList: res.data.list },
				})
			}
		},
		*accessCard({ payload }, { call, put }) {
			const res = yield call(accessApi, payload)
			if (res && res.data) {
				yield put({
					type: "updateState",
					payload: { accessCardList: res.data.list },
				})
			}
		},
		*query({ payload }, { call, put, select }) {
			const { selectedEstate = {} } = yield select(_ => _.app) || {}
			const params = { estate_id: selectedEstate.estate_id }
			const { estate_id } = params
			if (!estate_id)
				yield put({
					type: "app/getEstate",
				})
			const { name = "", to_user_id = -1 } = payload || {}
			const result = yield call(linkRecord, {
				estate_id,
				role: ROLE_TYPE.Occupant,
				name,
				to_user_id,
			}) || {}
			const data = (result || {}).data
			if (data) {
				yield put({
					type: "updateState",
					payload: { tableList: data.list },
				})
				yield put({
					type: "queryUnit",
				})
			}
		},
		*add({ payload }, { call, select, put }) {
			const { selectedEstate } = yield select(_ => _.app)
			const res = { estate_id: selectedEstate.estate_id }
			const params = {
				...res,
				role: Number(ROLE_TYPE.Occupant),
				email: payload.email,
				phone: payload.phone,
				unit_id: payload.unit_id,
				name: payload.name,
				legal_name: payload.legal_name,
				residency: Number(payload.residency),
				tenancy_expiration: payload.tenancy_expiration
					? payload.tenancy_expiration.unix()
					: -1,
			}
			const data = yield call(sendEmail, params)
			if (data && data.success) {
				yield put({ type: "query" })
				return data
			}
		},
		*edit({ payload }, { call, select, put }) {
			const {
				name,
				selectedRow = {},
				unit_id,
				email,
				phone,
				legal_name,
				residency,
				tenancy_expiration,
			} = payload
			const params = {
				legal_name,
				name,
				invitation_code_id: selectedRow.id,
				unit_id,
				email,
				phone,
				residency,
				tenancy_expiration: tenancy_expiration ? tenancy_expiration.unix() : -1,
			}
			const data = yield call(editTenant, params)
			if (data && data.success) {
				yield put({ type: "query" })
				return data
			}
		},
		*editSelf({ payload }, { call, put }) {
			const { name, unit_id } = payload
			const params = unit_id
				? {
						name,
						unit_id,
				  }
				: { name }
			const data = yield call(editSelfAPI, params)
			if (data && data.success) {
				const user = store.get("user")
				user.full_name = name
				user.unit_id = unit_id
				store.set("user", user)
				return data
			}
		},
		*addVehicle({ payload }, { call, put }) {
			const res = yield call(addVehi, payload)
			if (res && res.success) {
				message.success("success")
				yield put({ type: "vehicle", payload: { user_id: payload.user_id } })
				return res
			}
		},
		*editVehicle({ payload }, { call, put }) {
			const res = yield call(editVehi, payload)
			if (res && res.success) {
				message.success("success")
				return res
			}
		},
		*addAccess({ payload }, { call, put }) {
			const res = yield call(addAcc, payload)
			if (res && res.success) {
				message.success("success")
				yield put({ type: "accessCard", payload: { user_id: payload.user_id } })
				return res
			}
		},
		*delAccess({ payload }, { call, put }) {
			const res = yield call(delAcc, payload)
			if (res && res.success) {
				return res
			}
		},
		*delVehicle({ payload }, { call, put }) {
			const res = yield call(delVehi, payload)
			if (res && res.success) {
				return res
			}
		},
		*del({ payload }, { call, put }) {
			const res = yield call(delEmail, payload) || {}
			// const {data} = res;
			if (res && res.success) {
				yield put({ type: "query" })
				return res
			}
		},
	},
})
