/*
 * @Author: your name
 * @Date: 2020-09-18 22:07:20
 * @LastEditTime: 2021-08-16 16:46:24
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /basement/basementWeb/src/pages/assets/model.js
 */
// import modelExtend from 'dva-model-extend'
import api from "api"
import modelExtend from "dva-model-extend"
import { model } from "utils/model"
import { isEmpty } from "lodash"
import moment from "moment"
import { getSecondFromHour } from "utils"
import { message } from "antd"
import { pathToRegexp } from "path-to-regexp"
const {
	getAssetList,
	getSignatureUrl,
	getFacility,
	addAssetsUrl,
	editAssetsUrl,
	assetFileUrl,
	delAssetUrl,
	addFileDocUrl,
} = api
function clean(obj) {
	for (let propName in obj) {
		if (
			obj[propName] === null ||
			obj[propName] === undefined ||
			obj[propName] === NaN
		) {
			obj[propName] = -1
		}
	}
}
export default modelExtend(model, {
	namespace: "assets",
	state: {
		tableList: [],
		limit: 10,
		from: 0,
		// s3Url: "",s3Key:'',
		total: 0,
		facilityList: [],
		fileList: [],
	},
	subscriptions: {
		setup({ dispatch, history }) {
			history.listen(({ pathname }) => {
				if (pathToRegexp("/assets").exec(pathname)) {
					dispatch({ type: "app/getEstate" }).then(() => {
						dispatch({ type: "query" })
						dispatch({ type: "getFacilities" })
					})
				}
			})
		},
	},
	effects: {
		*del({ payload }, { select, put, call }) {
			const data = yield call(delAssetUrl, payload)
			if (data && data.success) {
				message.success("Success!")
				yield put({ type: "query" })
				return data
			}
		},
		*query({ payload: load = {} }, { call, put, select }) {
			const { selectedEstate } = yield select(_ => _.app)
			const params = { estate_id: selectedEstate.estate_id }

			const { estate_id } = params
			const {
				status: preStatus,
				limit: preLimit,
				from: preFrom,
				// order_by,
				// asc,
			} = yield select(_ => _.assets)
			yield put({
				type: "updateState",
				payload: {
					limit: load.limit - 0 || preLimit || 10,
					from: load.from - 0 === 0 ? 0 : load.from - 0 || preFrom || 0,
					// order_by,
					// asc,
				},
			})
			const { limit, from } = yield select(_ => _.assets)
			//TODO:需要修改的
			const result = yield call(getAssetList, {
				estate_id,
				limit,
				from,
				facility_id: load.facility_id || -1,
			}) || {}
			const data = (result || {}).data
			if (data) {
				yield put({
					type: "updateState",
					payload: {
						tableList: data.list.map(item => {
							const keyList = Object.keys(item)
							keyList.forEach(key => {
								if (item[key] !== -1 && key === "purchase_price")
									item[key] = item[key] / 100
								if (item[key] === -1) item[key] = undefined
							})
							return item
						}),
						total: data.total,
					},
				})
			}
		},
		*addOrEdit({ payload }, { call, put, select }) {
			// const { selectedEstate = {} } = yield select(_ => _.app) || {}
			// const estate_id = selectedEstate.id || 1
			// const { facilityName: name } = payload

			const { selectedEstate } = yield select(_ => _.app)
			const params = { estate_id: selectedEstate.estate_id }

			const { estate_id } = params
			const useful_life =
				payload.useful_life !== "" || !payload.useful_life
					? getSecondFromHour(payload.useful_life - 0) || undefined
					: undefined
			const warranty_date =
				payload.warranty_date && moment(payload.warranty_date).valueOf()
			const purchase_date =
				payload.purchase_date && moment(payload.purchase_date).valueOf()
			const purchase_price =
				payload.purchase_price !== "" || !payload.purchase_price
					? Math.floor((payload.purchase_price - 0) * 100) || undefined
					: undefined
			//status==='add' 添加
			const { img, doc, status, assetStatus, ...anotherPayload } = payload
			const imgContent =
				img && img.file && img.file.response && img.file.response.s3Key
			const typeImg =
				status === "add"
					? imgContent
						? [
								{
									type: 3,
									title: imgContent,
									mime_type: img.file.response.mime_type,
								},
						  ]
						: []
					: img
			const typeText =
				status === "add"
					? (doc &&
							doc.fileList &&
							doc.fileList
								.map(file => file.response)
								.map(file => ({
									type: 1,
									title: file.s3Key,
									mime_type: file.mime_type,
								}))) ||
					  []
					: doc
			const asset_files = [...(typeImg || []), ...(typeText || [])]
			const url = status === "add" ? addAssetsUrl : editAssetsUrl
			const load = {
				...anotherPayload,
				estate_id,
				status: assetStatus,
				purchase_price,
				useful_life,
				warranty_date,
				purchase_date,
				asset_files,
			}
			clean(load)
			const data = yield call(url, load)
			if (data) {
				message.success("Success.")
				yield put({ type: "query" })
				return true
			}
		},
		*addFileDoc({ payload }, { call, put }) {
			const data = yield call(addFileDocUrl, payload)
			if (data && data.success) {
				return Promise.resolve()
			}
			return Promise.reject(data)
		},
		*queryFile({ payload }, { call, put, select }) {
			const data = yield call(assetFileUrl, payload)
			if (data) {
				yield put({
					type: "updateState",
					payload: { fileList: data.data.list || [] },
				})
			}
		},
		// *edit({ payload }, { call, put, select }) {
		// 	// const { selectedEstate = {} } = yield select(_ => _.app) || {}
		// 	// const estate_id = selectedEstate.id || 1
		// 	// const { facilityName: name } = payload

		// 	const { selectedEstate } = yield select(_ => _.app)
		// 	const params = { estate_id: selectedEstate.estate_id }

		// 	const { estate_id } = params
		// 	const useful_life = getSecondFromHour(payload.useful_life - 0)
		// 	const warranty_date = moment(payload.warranty_date).unix()
		// 	const purchase_date = moment(payload.purchase_date).unix()
		// 	const purchase_price = payload.purchase_price - 0
		// 	const data = yield call(editAssetsUrl, {
		// 		...payload,
		// 		estate_id,
		// 		purchase_price,
		// 		useful_life,
		// 		warranty_date,
		// 		purchase_date,
		// 	})
		// 	if (data) {
		// 		yield put({ type: "query" })
		// 	}
		// },
		*getSignature({ payload }, { put, select, call }) {
			const { data = {} } = yield call(getSignatureUrl, payload)
			if (!isEmpty(data)) {
				return data
			}
		},
		*getFacilities({ payload }, { call, select, put }) {
			const { selectedEstate = {} } = yield select(_ => _.app) || {}
			const { estate_id } = selectedEstate
			const res = yield call(getFacility, { estate_id, building_type: -1 }) ||
				{}
			if (!res) return
			const { data } = res
			if (res && data) {
				yield put({
					type: "updateState",
					payload: { facilityList: data.list },
				})
			}
		},
	},
})
