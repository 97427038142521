/*
 * @Author: your name
 * @Date: 2021-01-24 00:10:06
 * @LastEditTime: 2021-07-22 18:31:34
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /basement/basementWeb/src/pages/receipt/model.js
 */
import { history } from "umi"
import api from "api"
import store from "store"
import { message } from "antd"
import { pathToRegexp } from "path-to-regexp"

const { bookingDetailUrl } = api

export default {
	namespace: "receipt",

	state: {
		detail: {},
		// confirmStatus: false,
	},
	subscriptions: {
		setup({ dispatch, history }) {
			history.listen(location => {
				if (pathToRegexp("/receipt").exec(location.pathname)) {
					const { booking_id } = location.query
					dispatch({
						type: "app/getEstate",
					})
					dispatch({
						type: "receipt/query",
						payload: { booking_id },
					})
				}
			})
		},
	},
	reducers: {
		changeDetail(state, { payload }) {
			return {
				...state,
				...payload,
			}
		},
	},
	effects: {
		*query({ payload }, { call, put }) {
			const { booking_id } = payload
			if (booking_id) {
				const data = yield call(bookingDetailUrl, { booking_id })
				if (!data) {
					// store.set("booking_id", booking_id)
					if (store.get("user")) {
						return yield put({ type: "app/signOut" })
					}
					return history.push("/")
				} else {
					// store.remove("booking_id");
					yield put({
						type: "changeDetail",
						payload: {
							detail: {
								booking_id,
								...data.data.booking,
								...data.data.venue_info,
							},
						},
					})
				}
			}
		},
	},
}
