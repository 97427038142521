/*
 * @Author: your name
 * @Date: 2021-06-06 20:54:04
 * @LastEditTime: 2021-09-08 16:58:11
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \basement\basementWeb\src\pages\payment\index.tsx
 */
import React, { useState, useEffect } from "react"
import { Page } from "components"
import {
	Col,
	DatePicker,
	Input,
	Row,
	Select,
	SelectProps,
	Table,
	Typography,
} from "antd"
import { ColumnsType } from "antd/lib/table"
import { useModel, useDispatch, history } from "umi"
import { moneyShow } from "utils"
import PaymentDrawer from "./components/Drawer"
import moment from "moment"
export const STATUS_MAP = new Map([
	[0, "Pending"],
	// [1, "Processing"],
	[1, "Cancelled"],
	[2, "Expired"],
	[3, "Failed"],
	[4, "Completed"],
	[5, "Deposited"],
	[6, "Refunded"],
])
export const PAYMENT_TYPE_MAP = new Map([
	[1, "Cash"],
	[2, "Pay Now"],
])
interface SelectionOptions extends SelectProps<any> {
	IconShow?: (map: Map<unknown, unknown>, key: unknown) => React.ReactNode
}
export const mapToSelect = (
	mapData: Map<number, V>,
	options?: SelectionOptions,
) => {
	const mapKey = [...(mapData?.keys() || [])]
	const { IconShow } = options || {}
	return (
		<Select {...options}>
			{mapKey?.map(key => {
				return (
					<Select.Option value={key}>
						{IconShow ? IconShow(mapData, key) : mapData.get(key)}
					</Select.Option>
				)
			})}
		</Select>
	)
}
function Payment(props) {
	// const state = useSelector(state => state)
	const paymentModal = useState(false)
	const [selectedRow, setSelectedRow] = useState({})
	const dispatch = useDispatch()
	const {
		pagnation,
		total,
		setPagnation,
		order_by,
		setOrder_by,
		asc,
		setAsc,
		user_name,
		setUser_name,
		dataList,
		time_start,
		setTime_start,
		time_end,
		setTime_end,
		status,
		setStatus,
		loading,
	} = useModel("payment") || {}
	const columns: ColumnsType<T> = [
		{
			title: "Amount",
			dataIndex: "amount",
			render(t) {
				return <>{moneyShow(t)}</>
			},
		},
		{
			title: "Status",
			dataIndex: "status",
			render(t) {
				return <>{STATUS_MAP.get(+t) || "-"}</>
			},
		},
		{
			title: "Reference ID",
			dataIndex: "biz_id",
			render(t, r) {
				return (
					<Typography.Link
						onClick={() => {
							history.push(`/bookings?bookingid=${r?.biz_id}`)
						}}
					>
						BO#{t}
					</Typography.Link>
				)
			},
		},
		{
			title: "Payment method",
			dataIndex: "pay_now",
			sorter: true,
			sortDirections: ["descend", "ascend", "descend"],
			// defaultSortOrder: asc === 1 ? "descend" : "ascend",
			render(t) {
				return <>{t ? "Pay Now" : "Cash"}</>
			},
		},
		{ title: "Customer", dataIndex: "user_name" },
		{
			title: "Last updated",
			dataIndex: "updated_at",
			sorter: true,
			sortDirections: ["descend", "ascend", "descend"],
			defaultSortOrder: asc === 1 ? "descend" : "ascend",
		},
	]
	useEffect(() => {
		dispatch({
			type: "app/getEstate",
		}).then(() => {
			setOrder_by("updated_at")
		})
	}, [])

	return (
		<Page>
			<Row style={{ padding: "30px 0" }} gutter={24}>
				{/* <Row gutter={24}> */}

				<Col span={6}>
					<Input
						value={user_name}
						onChange={e => setUser_name(e?.target?.value)}
						placeholder="Customer"
					/>
				</Col>
				<Col span={6}>
					<DatePicker.RangePicker
						// className={styles.vitem}
						format="MMM DD, YYYY"
						value={[
							time_start && moment.unix(time_start),
							time_end && moment.unix(time_end),
						]}
						disabledDate={current => current && current > moment().endOf("day")}
						onChange={val => {
							setTime_start(val?.[0].startOf("day").unix())
							setTime_end(val?.[1].endOf("day").unix())
						}}
					/>
				</Col>
				<Col span={6}>
					{mapToSelect(STATUS_MAP, {
						style: {
							minWidth: "250px",
						},
						placeholder: "Please select status",
						mode: "multiple",
						allowClear: true,
						maxTagCount: 2,
						value: status
							?.split(",")
							.filter(i => i !== "")
							.map(i => +i),
						onChange: val => setStatus(val.join(",") || ""),
					})}
				</Col>
			</Row>
			<Table
				onRow={record => ({
					onClick: () => {
						paymentModal[1](true)
						setSelectedRow({ ...record })
					},
				})}
				loading={loading}
				columns={columns}
				dataSource={dataList}
				onChange={(pagination, filters, sorter) => {
					const { field, order } = sorter || {}
					setAsc(order === "descend" ? 2 : 1)
					setOrder_by(field === "pay_now" ? "payment_type" : field)
				}}
				// FIXME:limit&from
				pagination={{
					current:
						Number(
							Math.floor((pagnation?.from || 0) / (pagnation?.limit || 10)) ||
								0,
						) + 1,
					pageSize: pagnation?.limit || 10,
					total: total || 0,
					// size: "small",
					showSizeChanger: false,
					// hideOnSinglePage: true,
					onChange(page) {
						setPagnation({
							limit: pagnation?.limit || 10,
							from: (Number(page) - 1) * (pagnation?.limit || 10),
						})
					},
				}}
			/>
			{/* </Row> */}
			<PaymentDrawer modal={paymentModal} selectedRow={selectedRow} />
		</Page>
	)
}
export default Payment
