/*
 * @Author: your name
 * @Date: 2020-09-18 22:07:20
 * @LastEditTime: 2021-07-22 18:30:41
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /basement/basementWeb/src/pages/facilities/model.js
 */
import { parse } from "qs"
// import modelExtend from 'dva-model-extend'
import api from "api"
import modelExtend from "dva-model-extend"
import { model } from "utils/model"
import { message } from "antd"
import { pathToRegexp } from "path-to-regexp"

const { getFacility, addFacility, editFacility, delFacility, getEstate } = api

export default modelExtend(model, {
	namespace: "facilities",
	state: {
		tableList: [],
	},
	subscriptions: {
		setup({ dispatch, history }) {
			history.listen(({ pathname }) => {
				if (pathToRegexp("/facilities").exec(pathname)) {
					dispatch({ type: "app/getEstate" }).then(() => {
						dispatch({ type: "query" })
					})
				}
			})
		},
	},
	effects: {
		*query({ payload }, { call, put, select }) {
			const { selectedEstate } = yield select(_ => _.app)
			const params = { estate_id: selectedEstate.estate_id }

			const { estate_id } = params
			const res = yield call(getFacility, { estate_id, building_type: -1 }) ||
				{}
			if (!res) return
			const { data } = res
			if (res && data) {
				yield put({
					type: "updateState",
					payload: { tableList: data.list },
				})
			}
		},
		*add({ payload }, { call, select, put }) {
			const { selectedEstate = {} } = yield select(_ => _.app) || {}
			const estate_id = selectedEstate.estate_id || 1
			const { facilityName: name, facilityType: building_type } = payload
			const data = yield call(addFacility, {
				estate_id,
				name,
				description: "",
				building_type,
			})
			if (data) {
				yield put({ type: "facilities/query" })
			}
			return data
		},
		*edit({ payload }, { call, select, put }) {
			// const {facilityName : name} = payload
			const data = yield call(editFacility, payload)
			if (data) {
				yield put({ type: "facilities/query" })
			}
			return data
		},
		*del({ payload }, { call, put }) {
			const data = yield call(delFacility, payload)
			if (data && data.success) {
				message.success(data.message || "success")
				yield put({ type: "facilities/query" })
			}
		},
	},
})
