// @ts-nocheck
import { Component } from 'react';
import { ApplyPluginsType } from 'umi';
import dva from 'dva';
// @ts-ignore
import createLoading from '/home/runner/work/basement/basement/node_modules/dva-loading/dist/index.esm.js';
import { plugin, history } from '../core/umiExports';
import ModelApp0 from '/home/runner/work/basement/basement/src/models/app.js';
import ModelDetail1 from '/home/runner/work/basement/basement/src/pages/user/[id]/models/detail.js';
import ModelModel2 from '/home/runner/work/basement/basement/src/pages/apps/bookings/model.js';
import ModelModel3 from '/home/runner/work/basement/basement/src/pages/apps/venues/model.js';
import ModelModel4 from '/home/runner/work/basement/basement/src/pages/assets/model.js';
import ModelModel5 from '/home/runner/work/basement/basement/src/pages/dashboard/model.js';
import ModelModel6 from '/home/runner/work/basement/basement/src/pages/facilities/model.js';
import ModelModel7 from '/home/runner/work/basement/basement/src/pages/login/model.js';
import ModelModel8 from '/home/runner/work/basement/basement/src/pages/occupants/model.js';
import ModelModel9 from '/home/runner/work/basement/basement/src/pages/receipt/model.js';
import ModelModel10 from '/home/runner/work/basement/basement/src/pages/register/model.js';
import ModelModel11 from '/home/runner/work/basement/basement/src/pages/resetPassword/model.js';
import ModelModel12 from '/home/runner/work/basement/basement/src/pages/team-member/model.js';
import ModelModel13 from '/home/runner/work/basement/basement/src/pages/units/model.js';
import ModelModel14 from '/home/runner/work/basement/basement/src/pages/user/model.js';
import dvaImmer, { enableES5, enableAllPlugins } from '/home/runner/work/basement/basement/node_modules/dva-immer/dist/index.js';

let app:any = null;

export function _onCreate(options = {}) {
  const runtimeDva = plugin.applyPlugins({
    key: 'dva',
    type: ApplyPluginsType.modify,
    initialValue: {},
  });
  app = dva({
    history,
    
    ...(runtimeDva.config || {}),
    // @ts-ignore
    ...(typeof window !== 'undefined' && window.g_useSSR ? { initialState: window.g_initialProps } : {}),
    ...(options || {}),
  });
  
  app.use(createLoading());
  app.use(dvaImmer());
  (runtimeDva.plugins || []).forEach((plugin:any) => {
    app.use(plugin);
  });
  app.model({ namespace: 'app', ...ModelApp0 });
app.model({ namespace: 'detail', ...ModelDetail1 });
app.model({ namespace: 'model', ...ModelModel2 });
app.model({ namespace: 'model', ...ModelModel3 });
app.model({ namespace: 'model', ...ModelModel4 });
app.model({ namespace: 'model', ...ModelModel5 });
app.model({ namespace: 'model', ...ModelModel6 });
app.model({ namespace: 'model', ...ModelModel7 });
app.model({ namespace: 'model', ...ModelModel8 });
app.model({ namespace: 'model', ...ModelModel9 });
app.model({ namespace: 'model', ...ModelModel10 });
app.model({ namespace: 'model', ...ModelModel11 });
app.model({ namespace: 'model', ...ModelModel12 });
app.model({ namespace: 'model', ...ModelModel13 });
app.model({ namespace: 'model', ...ModelModel14 });
  return app;
}

export function getApp() {
  return app;
}

/**
 * whether browser env
 * 
 * @returns boolean
 */
function isBrowser(): boolean {
  return typeof window !== 'undefined' &&
  typeof window.document !== 'undefined' &&
  typeof window.document.createElement !== 'undefined'
}

export class _DvaContainer extends Component {
  constructor(props: any) {
    super(props);
    // run only in client, avoid override server _onCreate()
    if (isBrowser()) {
      _onCreate()
    }
  }

  componentWillUnmount() {
    let app = getApp();
    app._models.forEach((model:any) => {
      app.unmodel(model.namespace);
    });
    app._models = [];
    try {
      // 释放 app，for gc
      // immer 场景 app 是 read-only 的，这里 try catch 一下
      app = null;
    } catch(e) {
      console.error(e);
    }
  }

  render() {
    let app = getApp();
    app.router(() => this.props.children);
    return app.start()();
  }
}
