/*
 * @Author: your name
 * @Date: 2020-11-23 14:58:11
 * @LastEditTime: 2021-09-04 01:35:54
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \ctg\basement\basementWeb\src\pages\unit\model.js
 */

import { parse } from "qs"
// import modelExtend from 'dva-model-extend'
import api from "api"
import modelExtend from "dva-model-extend"
import { model } from "utils/model"
import store from "store"
import { isEmpty } from "lodash"
import { pathToRegexp } from "path-to-regexp"

const {
	getUnit,
	getEstate,
	getFacility,
	addUnit: addUnitUrl,
	delUnit: delUnitUrl,
	editUnit: editUnitUrl,
	getOwner: getOwnerUrl,
} = api

export default modelExtend(model, {
	namespace: "unit",
	state: {
		limit: 10,
		from: 0,
		// total: 0,
		tableList: [],
		facilityList: [],
		ownerInfo: undefined,
		order_by: "created_at",
		asc: 2,
	},
	subscriptions: {
		setup({ dispatch, history }) {
			history.listen(({ pathname, query }) => {
				if (pathToRegexp("/units").exec(pathname)) {
					dispatch({ type: "app/getEstate" }).then(() => {
						dispatch({ type: "query", payload: { limit: 10, from: 0 } })
					})
				}
			})
		},
	},
	effects: {
		*facility({ payload }, { call, put, select }) {
			const { selectedEstate = {} } = yield select(_ => _.app) || {}
			const { estate_id } = selectedEstate
			const res = yield call(getFacility, { estate_id, building_type: 2 }) || {}
			if (!res) return
			const { data } = res
			if (res && data) {
				yield put({
					type: "updateState",
					payload: { facilityList: data.list },
				})
			}
		},
		*query({ payload: load = {} }, { call, put, select }) {
			const { selectedEstate, initEstateId } = yield select(_ => _.app)
			const params = { estate_id: selectedEstate.estate_id }

			const { estate_id } = params
			const {
				status: preStatus,
				limit: preLimit,
				from: preFrom,
				order_by,
				asc,
			} = yield select(_ => _.unit)
			yield put({
				type: "updateState",
				payload: {
					limit: load.limit - 0 || preLimit || 10,
					from: load.from - 0 === 0 ? 0 : load.from - 0 || preFrom || 0,
					order_by,
					asc,
				},
			})
			const { limit, from } = yield select(_ => _.unit)
			//TODO:需要修改的
			const result = yield call(getUnit, {
				limit: limit,
				from,
				estate_id,
				facility_id: load.facility_id || -1,
				asc,
				order_by,
			}) || {}
			const data = (result || {}).data
			if (data) {
				yield put({
					type: "updateState",
					payload: { tableList: data.list, total: data.total },
				})
			}
		},
		*addUnit({ payload }, { select, put, call }) {
			const data = yield call(addUnitUrl, payload)
			if (data && data.success) {
				return data
			}
		},
		*delUnit({ payload }, { select, put, call }) {
			const data = yield call(delUnitUrl, payload)
			if (data && data.success) {
				yield put({ type: "query" })
				return data
			}
		},
		*editUnit({ payload }, { select, put, call }) {
			const data = yield call(editUnitUrl, payload)
			if (data && data.success) {
				return data
			}
		},
		*sort({ payload }, { put }) {
			yield put({
				type: "updateState",
				payload,
			})
			yield put({ type: "query" })
		},
		*getOwner({ payload }, { select, put, call }) {
			const { selectedEstate } = yield select(_ => _.app)
			const params = { estate_id: selectedEstate.estate_id }

			const { estate_id } = params
			const res = yield call(getOwnerUrl, { ...payload, estate_id })
			if (res && res.success) {
				const { data = {} } = res
				yield put({
					type: "updateState",
					payload: {
						ownerInfo: (data && data.data) || {},
					},
				})
			}
		},
	},
})
