export const routeList = [
	{
		id: "1",
		name: "Dashboard",
		icon: "DashboardIcon",
		zh: {
			name: "仪表盘",
		},
		"pt-br": {
			name: "Dashboard",
		},
		route: "/dashboard",
	},
	// {
	// 	id: "0",
	// 	icon: "BookingIcon",
	// 	name: "Bookings",
	// 	zh: {
	// 		name: "预定",
	// 	},
	// 	"pt-br": {
	// 		name: "Bookings",
	// 	},
	// 	route: "/apps/bookings",
	// },
	{
		id: "6",
		breadcrumbParentId: "1",
		name: "Work Orders",
		icon: "MaintenanceIcon",
		route: "/work-orders",
	},
	{
		id: "2",
		breadcrumbParentId: "1",
		name: "Facilities",
		icon: "BuildingIcon",
		zh: {
			name: "建筑与设备",
		},
		"pt-br": {
			name: "Facilities",
		},
		// icon: 'user',
		// route: '/user',
	},
	{
		id: "21",
		breadcrumbParentId: "2",
		menuParentId: "2",
		name: "Facilities",
		zh: {
			name: "设备管理",
		},
		"pt-br": {
			name: "Facilities",
		},
		// icon: 'shopping-cart',
		route: "/facilities",
	},
	{
		id: "23",
		breadcrumbParentId: "2",
		menuParentId: "2",
		name: "Units",
		route: "/units",
	},
	{
		id: "24",
		breadcrumbParentId: "2",
		menuParentId: "2",
		name: "Assets",
		route: "/assets",
	},
	{
		id: "5",
		icon: "TenantIcon",
		breadcrumbParentId: "1",
		name: "People",
	},
	{
		id: "52",
		breadcrumbParentId: "5",
		menuParentId: "5",
		name: "Occupants",
		route: "/occupants",
	},
	{
		id: "53",
		breadcrumbParentId: "5",
		menuParentId: "5",
		name: "Contractors",
		route: "/contractors",
	},
	{
		id: "4",
		icon: "AppsIcon",
		breadcrumbParentId: "1",
		name: "Apps",
		route: "/apps",
	},

	//   {
	//     id: '3',
	//     menuParentId: '-1',
	//     breadcrumbParentId: '2',
	//     name: 'User Detail',
	//     zh: {
	//       name: '用户详情'
	//     },
	//     'pt-br': {
	//       name: 'Detalhes do usuário'
	//     },
	//     route: '/user/:id',
	//   },
	//   {
	//     id: '3',
	//     breadcrumbParentId: '1',
	//     name: 'Request',
	//     zh: {
	//       name: 'Request'
	//     },
	//     'pt-br': {
	//       name: 'Requisição'
	//     },
	//     icon: 'api',
	//     route: '/request',
	//   },
	//   {
	//     id: '4',
	//     breadcrumbParentId: '1',
	//     name: 'UI Element',
	//     zh: {
	//       name: 'UI组件'
	//     },
	//     'pt-br': {
	//       name: 'Elementos UI'
	//     },
	//     icon: 'camera-o',
	//   },
	//   {
	//     id: '45',
	//     breadcrumbParentId: '4',
	//     menuParentId: '4',
	//     name: 'Editor',
	//     zh: {
	//       name: 'Editor'
	//     },
	//     'pt-br': {
	//       name: 'Editor'
	//     },
	//     icon: 'edit',
	//     route: '/editor',
	//   },
	//   {
	//     id: '5',
	//     breadcrumbParentId: '1',
	//     name: 'Charts',
	//     zh: {
	//       name: 'Charts'
	//     },
	//     'pt-br': {
	//       name: 'Graficos'
	//     },
	//     icon: 'code-o',
	//   },
	//   {
	//     id: '51',
	//     breadcrumbParentId: '5',
	//     menuParentId: '5',
	//     name: 'ECharts',
	//     zh: {
	//       name: 'ECharts'
	//     },
	//     'pt-br': {
	//       name: 'ECharts'
	//     },
	//     icon: 'line-chart',
	//     route: '/chart/ECharts',
	//   },
	//   {
	//     id: '52',
	//     breadcrumbParentId: '5',
	//     menuParentId: '5',
	//     name: 'HighCharts',
	//     zh: {
	//       name: 'HighCharts'
	//     },
	//     'pt-br': {
	//       name: 'HighCharts'
	//     },
	//     icon: 'bar-chart',
	//     route: '/chart/highCharts',
	//   },
	//   {
	//     id: '53',
	//     breadcrumbParentId: '5',
	//     menuParentId: '5',
	//     name: 'Rechartst',
	//     zh: {
	//       name: 'Rechartst'
	//     },
	//     'pt-br': {
	//       name: 'Rechartst'
	//     },
	//     icon: 'area-chart',
	//     route: '/chart/Recharts',
	//   },
]
