import modelExtend from "dva-model-extend"
import api from "api"
import { model } from "utils/model"
import { ROLE_TYPE } from "utils/constant"
import { pathToRegexp } from "path-to-regexp"

const { queryDashboard, delEmail, sendEmail, linkRecord } = api

export default modelExtend(model, {
	namespace: "teamMember",
	subscriptions: {
		setup({ dispatch, history }) {
			history.listen(({ pathname }) => {
				if (pathToRegexp("/team-member").exec(pathname)) {
					dispatch({ type: "app/getEstate" }).then(() =>
						dispatch({ type: "dashboard/query" }),
					)
				}
			})
		},
	},
})
