/*
 * @Author: your name
 * @Date: 2020-09-09 23:00:26
 * @LastEditTime: 2021-07-22 22:14:59
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \basementWeb\src\utils\theme.js
 */
export const Color = {
	green: "#64ea91",
	blue: "#8fc9fb",
	purple: "#d897eb",
	red: "#f69899",
	yellow: "#f8c82e",
	peach: "#f797d6",
	borderBase: "#e5e5e5",
	borderSplit: "#f4f4f4",
	grass: "#d6fbb5",
	sky: "#c1e0fc",
}
