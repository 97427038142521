/*
 * @Author: your name
 * @Date: 2020-09-17 20:54:10
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /basement/basementWeb/src/pages/register/model.js
 */
import { history } from "umi"
import api from "api"
import { message } from "antd"
import { PushpinTwoTone } from "@ant-design/icons"
import { pathToRegexp } from "path-to-regexp"

const { signUpUrl } = api

export default {
	namespace: "register",

	state: {},
	subscriptions: {
		setup({ dispatch, history }) {
			history.listen(location => {
				if (pathToRegexp("/register").exec(location.pathname)) {
					dispatch({ type: "confirmMethod" })
				}
			})
		},
	},
	reducers: {},
	effects: {
		*confirmMethod(_, { select }) {
			const { invitationCode } = yield select(_ => _.login)
			const { code } = yield select(_ => _.app)
			if (!invitationCode) {
				if (code) {
					return
				}
				history.push("/login")
			}
		},
		*signUp({ payload }, { select, call, put }) {
			const { invitationCode: invitation_code } = yield select(_ => _.login)
			const { email, code, unit } = yield select(_ => _.app)
			const params = {
				...payload,
				email: payload.email || email,
				invitation_code: invitation_code || code,
				unit: unit,
			}
			const data = yield call(signUpUrl, params)
			if (data && data.success) {
				message.success("Success!")
				yield put({
					type: "app/updateState",
					payload: {
						redeem: undefined,
						code: undefined,
						email: undefined,
						unit: undefined,
					},
				})
				yield put({
					type: "login/login",
					payload: {
						email: params.email,
						password: params.password,
					},
				})
			}
		},
	},
}
