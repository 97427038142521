/*
 * @Author: yanlin.shen
 * @Email: yanlin.shen@chinatalentgroup.com
 * @Date: 2020-09-23 09:27:34
 * @LastAuthor: yanlin.shen
 * @LastTime: 2020-09-23 14:05:19
 * @message:
 */
export const apiPrefix = "/api/v1"
export default {
	loginTitle: "Sign In",
	siteName: "Basementgrid",
	copyright: "basementgrid @2020",
	logoPath: "/Logo_dark.svg",
	menuLogo: "/logo/icon_menu.svg",
	fixedHeader: true, // sticky primary layout header

	/* Layout configuration, specify which layout to use for route. */
	layouts: [
		{
			name: "primary",
			include: [/.*/],
			exclude: [
				/(\/(en|zh))*(\/login)|(\/register)|(\/receipt)|(\/resetPassword)/,
			],
		},
	],
}
/* I18n configuration, `languages` and `defaultLanguage` are required currently. */
export const i18n = {
	/* Countrys flags: https://www.flaticon.com/packs/countrys-flags */
	languages: [
		{
			key: "pt-br",
			title: "Português",
			flag: "/portugal.svg",
		},
		{
			key: "en",
			title: "English",
			flag: "/america.svg",
		},
		{
			key: "zh",
			title: "中文",
			flag: "/china.svg",
		},
	],
	defaultLanguage: "en",
}
