/*
 * @Author: yanlin.shen
 * @Email: yanlin.shen@chinatalentgroup.com
 * @Date: 2020-09-14 09:21:51
 * @LastAuthor: yanlin.shen
 * @LastTime: 2020-09-22 09:50:57
 * @message:
 */
import request from "utils/request"
import { apiPrefix } from "utils/config"
import { message as msg } from "antd"
import api from "./api"
import store from "store"
import { history } from "umi"
let count = 0
const gen = params => {
	let url = apiPrefix + params
	let method = "GET"
	const paramsArray = params.split(" ")
	if (paramsArray.length === 2) {
		method = paramsArray[0]
		let paramsArrayUrl = ""
		paramsArrayUrl =
			process.env.NODE_ENV === "development"
				? paramsArray[1]
				: paramsArray[1].replace("/remote", "")
		url = apiPrefix + paramsArrayUrl
	} else {
		url =
			process.env.NODE_ENV === "development" ? url : url.replace("/remote", "")
	}
	return function(data, options) {
		if (options) {
			return request({ url, data, method }, options)
		}
		return request({ url, data, method })
			.then(jsonData => {
				const data = jsonData || {}
				const { message, code, statusCode } = data
				if (statusCode === 200 && code !== 200) {
					if (message) {
						//token error
						if (code === 200003) {
							msg.error(message)
							store.clearAll()
							history.push("/login")
							window.location.reload()
							return
						}
						if (code === 200039) {
							count === 0 && msg.error(message)
							count === 0 && msg.info("reloading...")
							count++
							setTimeout(() => {
								// store.clearAll()
								// history.push("/login")
								window.location.reload()
								count = 0
							}, 2000)
							return
						}
					} else {
						/* eslint-disable */
						console.error("error:", jsonData)
					}
					msg.error(message || "Something error.")
					return Promise.resolve({
						...jsonData,
						data: {},
						success: false,
					})
				}
				return Promise.resolve({
					success: true,
					message: message || "Success.",
					...jsonData,
				})
			})
			.catch(e => {
				if (e.message) {
					msg.error(e.message)
				} else {
					/* eslint-disable */
					console.error(e)
				}
			})
	}
}

const APIFunction = {}
function getApi(api) {
	for (const key in api) {
		if (typeof api[key] === "string") {
			APIFunction[key] = gen(api[key])
		} else {
			getApi(api[key])
		}
	}
}

getApi(api)
// APIFunction.queryWeather = params => {
//   params.key = 'i7sau1babuzwhycn'
//   return request({
//     url: `${apiPrefix}/weather/now.json`,
//     data: params,
//   })
// }

export default APIFunction
