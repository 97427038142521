/*
 * @Author: your name
 * @Date: 2021-06-06 20:56:12
 * @LastEditTime: 2021-09-08 17:26:51
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \basement\basementWeb\src\models\payment.tsx
 */
import { useState, useEffect, useCallback } from 'react';
import API from "@/services"
import moment from 'moment';
let timer;
const debounce = (fn, time = 1000) => {
  // let timer
  return (...args) => {
    // console.log(args,fn)
    // if(!timer){
    //   fn.apply(this, args);
    // }
    clearTimeout(timer)
    timer = setTimeout(() => {
      fn.apply(this, args)
    }, time)
  }
}
export const init_page = { limit: 10, from: 0 }
export default function useContractor() {
  const [pagnation, setPagnation] = useState(init_page);
  const [total, setTotal] = useState(0)
  // const [order_by, setOrder_by] = useState<string>('')
  // const [asc, setAsc] = useState(2);
  // const [facility_id, setFacility_id] = useState(-1)
  // const [user_name, setUser_name] = useState('');
  const [estate_id, setEstateId] = useState()
  const request = (data) => { setLoading(true); return API.getContractor(data) }
  const getData = async () => request({
    ...pagnation, estate_id//user_name, time_start, time_end, status
  }).then(data => {
    const list = data?.data?.list || [];
    const total = data?.data?.total || 0
    setDataList(list)
    setLoading(false)
    // setPagnation({ ...pagnation, })
    setTotal(total)
  }).finally(() => setLoading(false))
  const [dataList, setDataList] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    estate_id && getData()
  }, [pagnation, estate_id])
  // ((account, password) => {
  //   request({
  //     ...pagnation,order_by,asc,userId
  //   })
  // }, [])
  const handleAdd = async (value) => {
    setLoading(true);
    await API.createContractor(value);
    getData();
  }
  const handleEdit = async (value) => {
    setLoading(true);
    await API.editContractor(value);
    getData();
  }
  const handleDel = async (value) => {
    setLoading(true);
    await API.delContractor(value);
    getData();
  }
  return {
    pagnation, setPagnation, dataList, loading, total, setEstateId,//,user_name, setUser_name,
    handleAdd, handleEdit, handleDel
  }
}