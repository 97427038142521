/*
 * @Author: your name
 * @Date: 2021-06-11 18:02:52
 * @LastEditTime: 2021-06-17 14:10:28
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \basement\basementWeb\src\utils\request.ts
 */
interface requestList {
  url: string;
  data?: POJO;
}
class RequestControl {
  constructor(props) {

    this.maxLimit = props.maxLimit;
    //当前并发数量
    this.requestNumber = 0;
    //请求阻塞队列
    this.requestList = [];
    this.request = props.request;
  }
  requestNumber: number;
  maxLimit: number;
  requestList: (() => Promise<T>)[];
  request: (...args) => Promise<T>
  // requestStart() {
  //   let _res;
  //   let promise = new Promise((res,rej)=>_res=res)
  //   this.requestList.push(_res)
  //   return promise;
  // }
  nextRequest() {
    if (this.requestList.length <= 0) return;
    const _res = this.requestList.shift();
    _res()
  }
  requestFinish() {
    this.requestNumber--;
  }
  // 发起请求api
  async requestControl(...args) {
    // 若当前请求数并发量超过最大并发量限制，则将其阻断在这里。
    // startBlocking会返回一个promise，并将该promise的resolve函数放在this.requestQueue队列里。这样的话，除非这个promise被resolve,否则不会继续向下执行。
    // 当之前发出的请求结果回来/请求失败的时候，则将当前并发量-1,并且调用this.next函数执行队列中的请求
    // 当调用next函数的时候，会从this.requestQueue队列里取出队首的resolve函数并且执行。这样，对应的请求则可以继续向下执行。
    if (this.requestNumber >= this.maxLimit) {
      await this.startBlocking();
    }
    try {
      this.requestNumber++;
      const result = await this.request(...args);
      return Promise.resolve(result);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      console.log('当前并发数:', this.requestNumber);
      this.requestFinish();
      this.nextRequest();
    }
  }
  // 新建一个promise,并且将该reolsve函数放入到requestQueue队列里。
  // 当调用next函数的时候，会从队列里取出一个resolve函数并执行。
  startBlocking() {
    let _resolve;
    let promise2 = new Promise((resolve, reject) => _resolve = resolve);
    this.requestList.push(_resolve);
    return promise2;
  }

}
export default RequestControl