/*
 * @Author: yanlin.shen
 * @Email: yanlin.shen@chinatalentgroup.com
 * @Date: 2020-09-18 09:27:58
 * @LastAuthor: yanlin.shen
 * @LastTime: 2020-09-18 10:58:17
 * @message:
 */
import { parse } from "qs"
// import modelExtend from 'dva-model-extend'
import api from "api"
import store from "store"
import modelExtend from "dva-model-extend"
import { model } from "utils/model"
import { message } from "antd"
import moment from "moment"
import { isEmpty } from "lodash"
import { ROLE_TYPE } from "utils/constant"
import { pathToRegexp } from "path-to-regexp"

const {
	getTimeSlots,
	getVenueList,
	timeSlot,
	payment: paymentUrl,
	bookingStatus,
	getVenuesBookingList,
	getEstate,
	addBookings,
	bookingPaymentStatus,
	linkRecord,
	userBooking,
	cancelBookingByUser,
	gethelpUrl,
	sendEmailUrl,
	setRemarkUrl,
	bookingDetailUrl,
	getCoordinator,
} = api
const date = new Date()
const bookingStart = moment(date)
	.subtract("7", "day")
	.startOf("day")
export default modelExtend(model, {
	namespace: "bookings",
	state: {
		list: [],
		venueList: [],
		timeList: [],
		status: [1, 2, 3, 4, 5],
		timeSlotList: [],
		limit: 10,
		user_name: "",
		venue_ids: undefined,
		from: 0,
		order_by: "created_at",
		bookingStart,
		bookingEnd: moment(date)
			.add(1, "month")
			.endOf("day"),
		asc: 2,
		userDetail: {},
		loading: false,
	},
	subscriptions: {
		setup({ dispatch, history }) {
			history.listen(({ pathname, query }) => {
				dispatch({ type: "loading" })
				if (pathToRegexp("/apps/bookings").exec(pathname) && query.status) {
					dispatch({ type: "app/getEstate" }).then(() => {
						dispatch({ type: "venue" }).then(() => {
							dispatch({ type: "query", payload: { status: query.status } })
							// dispatch({ type: "loading" })
						})
					})

					return
				}
				if (pathToRegexp("/apps/bookings").exec(pathname) && query.add) {
					dispatch({ type: "app/getEstate" }).then(() => {
						dispatch({ type: "venue" }).then(() => {
							dispatch({ type: "query", payload: { add: query.add } })
							// dispatch({ type: "loading" })
						})
					})
					return
				}
				if (pathToRegexp("/apps/bookings").exec(pathname)) {
					dispatch({ type: "app/getEstate" }).then(() => {
						dispatch({ type: "venue" }).then(() => {
							dispatch({ type: "query" })
							// dispatch({ type: "loading" })
						})
					})
				}
			})
		},
	},
	reducers: {
		loading(state) {
			return {
				...state,
				loading: !state.loading,
			}
		},
	},
	effects: {
		*addBooking({ payload }, { call, put, select }) {
			const user_name = (store.get("user") || {}).full_name
			const data = yield call(addBookings, { ...payload, user_name })
			if (data && data.success) {
				message.success("Booking success!")
				yield put({ type: "bookings/query" })
				return data
			} else {
				// message.error((data && data.data && data.data.message) || "add error")
				return {}
			}
		},
		*sendPDFEmail({ payload }, { call, put }) {
			const { id: booking_id } = payload
			const res = yield call(sendEmailUrl, { booking_id })
			if (!res) return message.error("Send emil fail.")
			return message.success("success")
		},
		*remark({ payload }, { call, put }) {
			const { booking_id, remarks } = payload
			const res = yield call(setRemarkUrl, { booking_id, remarks })
			if (!res) return false
			yield put({ type: "query" })
			return message.success("success")
		},
		*query({ payload }, { call, put, select }) {
			const load = payload || {}
			const { add } = load
			if (add) {
				yield put({
					type: "updateState",
					payload: {
						add: true,
					},
				})
				yield put({
					type: "bookings/venue",
				})
				const params = yield select(_ => _.venues.params) || {}
				if (!isEmpty(params)) {
					const { venue_id, day, week, month, year } = params
					yield put({
						type: "getBookingList",
						payload: {
							venue_id,
							day,
							week,
							month,
							year,
						},
					})
				} else {
					yield put({
						type: "updateState",
						payload: {
							add: undefined,
						},
					})
				}
			}
			const {
				status: preStatus,
				limit: preLimit,
				from: preFrom,
				bookingStart,
				bookingEnd,
			} = yield select(_ => _.bookings)

			yield put({
				type: "updateState",
				payload: {
					status: load.status - 0 || preStatus || -1,
					limit: load.limit - 0 || preLimit || 10,
					from: load.from - 0 === 0 ? 0 : load.from - 0 || preFrom || 0,
				},
			})
			const { selectedEstate } = yield select(_ => _.app)
			const params = { estate_id: selectedEstate.estate_id }
			const { role } = selectedEstate
			const {
				status,
				limit,
				from,
				order_by,
				asc,
				user_name,
				venue_ids,
			} = yield select(_ => _.bookings)
			const sweepStatus = status => {
				if (typeof status === "number") return String(status)
				if (typeof status === "string") return status
				return status.join(",")
			}
			const res =
				store.get("resident") && role !== 8
					? yield call(userBooking, {
							...params,
							status: sweepStatus(status),
							venue_id: venue_ids,
							limit,
							from,
							order_by,
							asc,
							// booking_start: -1,
							// booking_end: moment("2099-12-30").valueOf(),
					  }) || {}
					: yield call(getTimeSlots, {
							...params,
							user_name,
							venue_id: venue_ids,
							status: sweepStatus(status),
							limit,
							from,
							order_by,
							asc,
							booking_start: moment(bookingStart).valueOf(),
							booking_end: moment(bookingEnd).valueOf(),
					  }) || {}
			yield put({ type: "updateState", payload: { loading: false } })
			if (!res) return
			const { data } = res
			if (data && data.list && Array.isArray(data.list)) {
				yield put({
					type: "updateState",
					payload: { list: data && data.list, total: data && data.total },
				})
			}
		},
		*getBookingDetail({ payload }, { call, put }) {
			const data = yield call(bookingDetailUrl, {
				booking_id: payload.bookingid,
			})
			return data
		},
		*sort({ payload }, { put }) {
			yield put({
				type: "updateState",
				payload,
			})
			yield put({ type: "query" })
		},
		*venue({ payload }, { call, select, put }) {
			const { selectedEstate } = yield select(_ => _.app)
			const estate_id = selectedEstate.estate_id || 1
			const res = yield call(getVenueList, { estate_id, limit: -1, from: 0 }) ||
				{}
			const data = res && res.data
			if (data && data.list && Array.isArray(data.list)) {
				const venueList = data && data.list
				yield put({
					type: "updateState",
					payload: { venueList },
				})
			}
		},
		*queryUserDetail({ payload }, { call, put, select }) {
			const { selectedEstate = {} } = yield select(_ => _.app) || {}
			const params = { estate_id: selectedEstate.estate_id }

			const { estate_id } = params

			const { name = "", to_user_id = -1, id, c_id } = payload || {}
			const result = yield call(
				c_id ? getCoordinator : linkRecord,
				c_id
					? { contractor_id: c_id }
					: {
							estate_id,
							role: `${ROLE_TYPE.Occupant},${ROLE_TYPE.Owner},${ROLE_TYPE.Security},${ROLE_TYPE.Administrator},${ROLE_TYPE["Limited Member"]}`,
							name,
							to_user_id,
							id,
							...payload,
					  },
			) || {}
			const data = (result || {}).data
			if (data) {
				yield put({
					type: "updateState",
					payload: {
						userDetail: c_id
							? {
									name: data.work_order.company || "",
									phone: data.work_order.phone,
									email: data.work_order.email,
							  }
							: data.list && data.list[0],
					},
				})
			}
		},
		*getTimeSlot({ payload }, { select, put, call }) {
			const { timeList } = yield select(_ => _.bookings)
			if (timeList && timeList.length) {
				return
			}
			const res = yield call(timeSlot) || {}
			if (!res) return
			const { data } = res
			if (data && data.list && Array.isArray(data.list)) {
				const timeList = data && data.list
				yield put({
					type: "updateState",
					payload: { timeList },
				})
			}
		},
		*payment({ payload }, { call }) {
			const res = yield call(paymentUrl, payload)
			if (!res) return
			const { data } = res
			return data
		},
		*getBookingList({ payload }, { call, put }) {
			const { venue_id, day, week, month, year } = payload
			const params = { venue_id, day, week, month, year }
			const res = yield call(getVenuesBookingList, params) || {}
			if (!res) return
			const { data } = res
			if (data && data.list && Array.isArray(data.list)) {
				const { list: timeSlotList = [] } = data
				yield put({
					type: "updateState",
					payload: { timeSlotList, ...payload },
				})
			}
		},
		*editStatus({ payload }, { call, put }) {
			const data = store.get("resident")
				? yield call(cancelBookingByUser, { booking_id: payload.booking_id })
				: yield call(bookingStatus, payload)
			if (data && data.success) {
				message.success(data.message || "success")
				yield put({
					type: "query",
				})
			}
		},
		*editPaymentStatus({ payload }, { call, put }) {
			const data = yield call(bookingPaymentStatus, payload)
			if (data && data.success) {
				message.success(data.message || "success")
				yield put({
					type: "query",
				})
			} else {
				return Promise.reject(false)
			}
		},
		//TODO:确认接口
		*cancel({ payload }, { call, put }) {
			const data = yield call(bookingStatus, { ...payload, status: 2 })
			console.log(data)
		},
		*gethelp({ payload }, { call, put }) {
			const data = yield call(gethelpUrl, { ...payload })
			if (data && data.success) {
				message.success("success")
			}
		},
	},
})
