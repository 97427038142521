/*
 * @Author: your name
 * @Date: 2020-12-21 11:04:18
 * @LastEditTime: 2021-01-16 23:04:08
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \basement\basementWeb\src\app.js
 */
import { basement } from "../package.json"
const { VERSION, BUILD_TIME } = basement
console.log(`${process.env.NODE_ENV}:${VERSION}`)
BUILD_TIME && console.log(`build time:${BUILD_TIME} by GITHUB`)
const log = console.log
console.log = function(...args) {
	if (process.env.NODE_ENV !== "development") {
		return
	}
	return log(...args)
}
document.querySelector("#primaryLayout") &&
	document.querySelector("#primaryLayout").addEventListener(
		"touchmove",
		function(e) {
			e.preventDefault()
			// document.body.scrollTop = 0;

			//阻止默认的处理方式(阻止下拉滑动的效果)
		},
		{ passive: false },
	) //passive 参数不能省略，用来兼容ios和android
;(function overscroll(el) {
	if (!el) return
	el.addEventListener("touchstart", function() {
		let top = el.scrollTop
		let totalScroll = el.scrollHeight
		let currentScroll = top + el.offsetHeight
		if (top === 0) {
			el.scrollTop = 1
		} else if (currentScroll === totalScroll) {
			el.scrollTop = top - 1
		}
	})

	el.addEventListener("touchmove", function(evt) {
		if (el.offsetHeight < el.scrollHeight) evt._isScroller = true
	})
})(document.querySelector("#primaryLayout"))
