/*
 * @Author: your name
 * @Date: 2020-09-29 21:57:12
 * @LastEditTime: 2020-10-29 22:33:22
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \basementWeb\src\utils\SVGMapping.js
 */
import React from "react"
// ./public
import { ReactComponent as DashboardIcon } from "../../public/icons/dashboard.svg"
import { ReactComponent as BookingIcon } from "../../public/icons/booking.svg"
import { ReactComponent as BuildingIcon } from "../../public/icons/building.svg"
import { ReactComponent as TenantIcon } from "../../public/icons/tenant.svg"
import { ReactComponent as LogOut } from "../../public/icons/logout.svg"
const mapSvg = {
	DashboardIcon,
	BookingIcon,
	BuildingIcon,
	TenantIcon,
}
export const getSVG = iconName => mapSvg[iconName]
export default mapSvg
export const publicUrl = icon => {
	const rootUrl = "./icons/icon_png/"
	let url = ""
	switch (icon) {
		case "DashboardIcon":
			url = "icon_booking"
			break
		case "BookingIcon":
			url = "icon_booking"
			break
		case "BuildingIcon":
			url = "icon_building"
			break
		case "TenantIcon":
			url = "icon_tenant"
			break
		default:
			break
	}
	return rootUrl + url + "_grey.png"
}
