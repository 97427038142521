/*
 * @Author: your name
 * @Date: 2020-10-20 21:10:39
 * @LastEditTime: 2020-10-22 23:27:45
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: /basement/basementWeb/src/pages/login/resident_route.js
 */
export const routeList = [
	// {
	//   id: "1",
	//   name: "Dashboard",
	//   icon: "DashboardIcon",
	//   zh: {
	//     name: "仪表盘",
	//   },
	//   "pt-br": {
	//     name: "Dashboard",
	//   },
	//   route: "/dashboard",
	// },
	{
		id: "0",
		icon: "BookingIcon",
		name: "Bookings",
		zh: {
			name: "预定",
		},
		"pt-br": {
			name: "Bookings",
		},
		route: "/apps/bookings",
	},
	{
		id: "6",
		breadcrumbParentId: "1",
		name: "Work Orders",
		icon: "MaintenanceIcon",
		route: "/work-orders",
	},
	{
		id: "7",
		icon: "AppsIcon",
		name: "Notices",
		zh: {
			name: "公告",
		},
		breadcrumbParentId: "1",
		"pt-br": {
			name: "Notices",
		},
		route: "/apps/notices",
	},
	// {
	//   id: "2",
	//   breadcrumbParentId: "1",
	//   name: "Buildings and Facilities",
	//   icon: "BuildingIcon",
	//   zh: {
	//     name: "建筑与设备",
	//   },
	//   "pt-br": {
	//     name: "Buildings and Facilities",
	//   },
	//   // icon: 'user',
	//   // route: '/user',
	// },
	// {
	//   id: "21",
	//   breadcrumbParentId: "2",
	//   menuParentId: "2",
	//   name: "Facilities",
	//   zh: {
	//     name: "设备管理",
	//   },
	//   "pt-br": {
	//     name: "Facilities",
	//   },
	//   // icon: 'shopping-cart',
	//   route: "/facilities",
	// },
	// {
	//   id: "22",
	//   breadcrumbParentId: "2",
	//   menuParentId: "2",
	//   name: "Venues",
	//   route: "/venues",
	// },
	// //   {
	// //     id:'23',
	// //     breadcrumbParentId: '2',
	// //   menuParentId: '2',
	// //   name:'Assets',
	// //   route:'/assets'
	// // },
	// {
	//   id: "3",
	//   icon: "TenantIcon",
	//   breadcrumbParentId: "1",
	//   name: "Tenants",
	//   route: "/tenants",
	// },
]
