/*
 * @Author: your name
 * @Date: 2021-06-06 20:56:12
 * @LastEditTime: 2021-06-27 22:30:47
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \basement\basementWeb\src\models\payment.tsx
 */
import { useState, useEffect, useCallback } from 'react';
import API from "@/services"
import moment from 'moment';
let timer;
const debounce = (fn, time = 1000) => {
  // let timer
  return (...args) => {
    // console.log(args,fn)
    // if(!timer){
    //   fn.apply(this, args);
    // }
    clearTimeout(timer)
    timer = setTimeout(() => {
      fn.apply(this, args)
    }, time)
  }
}
export default function useAuthModel() {
  const [pagnation, setPagnation] = useState({ limit: 10, from: 0 });
  const [total, setTotal] = useState(0)
  const [order_by, setOrder_by] = useState<string>('')
  const [asc, setAsc] = useState(2);
  const [user_name, setUser_name] = useState('');
  const [time_start, setTime_start] = useState(moment().startOf('day').unix())
  const [time_end, setTime_end] = useState(moment().endOf('day').unix())
  const [status, setStatus] = useState('0,1,2,3,4,5,6')
  const request = (data) => { setLoading(true); return API.paymentList(data) }
  const getData = () => request({
    ...pagnation, order_by, asc, user_name, time_start, time_end, status
  }).then(data => {
    const list = data?.data?.list || [];
    const total = data?.data?.total || 0
    setDataList(list)
    setLoading(false)
    // setPagnation({ ...pagnation, })
    setTotal(total)
  }).finally(() => setLoading(false))
  const [dataList, setDataList] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    order_by && getData()
  }, [pagnation, order_by, asc, time_start, time_end, status])
  useEffect(() => {
    order_by && debounce(getData)()
  }, [user_name])
  // ((account, password) => {
  //   request({
  //     ...pagnation,order_by,asc,userId
  //   })
  // }, [])

  return {
    pagnation, setPagnation, order_by, setOrder_by, asc, setAsc, user_name, setUser_name, dataList, time_start, setTime_start, time_end, setTime_end, status, setStatus, loading, total
  }
}