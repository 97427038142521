/*
 * @Author: your name
 * @Date: 2021-06-08 14:18:00
 * @LastEditTime: 2021-06-29 13:30:40
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \basement\basementWeb\src\pages\payment\components\DetailItem.tsx
 */
import Payment from "@/pages/apps/bookings/Payment"
import { Button } from "antd"
import React, { useState } from "react"
import { moneyShow } from "utils"
import { PAYMENT_TYPE_MAP, STATUS_MAP } from ".."
import styles from "./index.less"

export default function DetailItem(props) {
	const { record } = props
	const payment = useState(false)
	// const [paymentDetail, setPaymentDetail] = useState({})
	return (
		<>
			<div className={styles.detail_item}>
				<div className={styles.title}>
					<div className={styles.pay}>
						<h1>{moneyShow(record?.amount)}</h1>
						<Button
							onClick={() => {
								payment[1](true)
							}}
						>
							Refund ...
						</Button>
					</div>
					<h2>{record?.description}</h2>
				</div>
				<div className={styles.detail}>
					<div className={styles.item}>
						<span className={styles.label}>ID</span>
						<span className={styles.value}>{record?.no}</span>
					</div>
					<div className={styles.item}>
						<span className={styles.label}>Status</span>
						<span className={styles.value}>
							{STATUS_MAP.get(+record?.status)}
						</span>
					</div>
					<div className={styles.item}>
						<span className={styles.label}>Payment method</span>
						<span className={styles.value}>
							{PAYMENT_TYPE_MAP.get(+record?.payment_type)}
						</span>
					</div>
					<div className={styles.item}>
						<span className={styles.label}>Customer</span>
						<span className={styles.value}>{record?.user_name || "-"}</span>
					</div>
					<div className={styles.item}>
						<span className={styles.label}>Last updated</span>
						<span className={styles.value}>{record?.updated_at || "-"}</span>
					</div>
					<div className={styles.item}>
						<span className={styles.label}>Created on</span>
						<span className={styles.value}>{record?.created_at || "-"}</span>
					</div>
				</div>
			</div>
			<Payment
				amount={record?.pay_now?.amount}
				modal={payment}
				paymentDetail={record}
			/>
		</>
	)
}
