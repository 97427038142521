/*
 * @Author: your name
 * @Date: 2021-06-14 13:14:06
 * @LastEditTime: 2021-06-27 22:26:07
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \basement\basementWeb\src\pages\bookings\PayModal.tsx
 */
import { Button, Modal } from "antd"
import React, { useEffect } from "react"
import styles from "./index.less"
import { moneyShow } from "utils"

export default function Payment(props) {
	const { modal, paymentDetail, onClose, amount } = props
	const qrcode = paymentDetail?.pay_now?.qrcode
		? `data:image/png;base64,${paymentDetail?.pay_now?.qrcode}`
		: ""
	const [visible, setVisible] = modal
	const bodyStyle = {
		display: "flex",
		flexDirection: "column",
		alignContent: "stretch",
		alignItems: "center",
	}
	return (
		<Modal
			bodyStyle={bodyStyle}
			title={"Pay using PayNow"}
			visible={visible}
			onCancel={() => {
				setVisible(false)
				onClose?.()
			}}
			footer={null}
			className={styles.payment_modal}
		>
			<p>
				If you’re on desktop, scan the QR code with your ibanking app. If you’re
				accessing the page on mobile, follow the instructions below.
			</p>
			<br />
			<p>1. Click on save image button.</p>
			<p>2. Go to your ibanking app.</p>
			<p> 3. Upload your QR code and make the payment.</p>
			<hr style={{ width: "100%" }} />
			<h2>{moneyShow(amount)}</h2>
			<div>
				<img src={qrcode} />
			</div>
			<Button
				onClick={() => {
					setVisible(false)
					window.location.reload()
				}}
				type="primary"
				style={{ width: "100%" }}
			>
				I HAVE ALREADY PAID
			</Button>
			<Button
				onClick={() => {
					setVisible(false)
					onClose?.()
				}}
				style={{ width: "100%" }}
			>
				I WILL PAY LATER
			</Button>
			<hr style={{ width: "100%" }} />

			<Button style={{ width: "100%" }}>NEED ANY HELP?</Button>
		</Modal>
	)
}
