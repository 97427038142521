/*
 * @Author: your name
 * @Date: 2020-09-09 23:00:26
 * @LastEditTime: 2021-09-08 15:59:53
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \basementWeb\src\utils\constant.js
 */
export const ROLE_TYPE = {
	Administrator: 2,
	Security: 8,
	"Limited Member": 16,
	Owner: 4,
	Occupant: 1,
}
export const ROLE_TEXT = {
	Administrator: "Full access",
	Security: "View only",
	"Limited Member": "Limited access",
	Owner: "Full access",
	Occupant: 1,
}
export const CANCEL_REQUEST_MESSAGE = "cancel request"
export const BOOKING_STATUS = new Map([
	[1, "Pending"],
	[2, "Canceled"],
	[3, "Completed"],
	[4, "Expired"],
	[5, "Closed"],
])
export const statusIcon = new Map([
	// [0, "#DD3030"], // Pending,
	[1, "#DD3030"], //"Canceled"],
	[2, "#7E808D"], // "Expired"],
	[3, "#27AD5A"], //"Completed"],
	[4, "#bcbcbc"], //"Closed"],
	[5, "#000000"], //"Closed"],
])
export const TYPEMAP = new Map([
	["1", "Residential"],
	["2", "Commercial"],
	["3", "Mixed"],
])
// [0, "Pending"],
// [1, "Cancelled"],
// [2, "Expired"],
// [3, "Failed"],
// [4, "Completed"],
// [5, "Deposited"],
// [6, "Refunded"],
export { STATUS_MAP as PAYMENT_STATUS } from "@/pages/payment"
export const CASH_PAYMENT_STATUS = new Map([
	[1, "Unpaid "],
	[2, "Paid"],
	[3, "Refunded"],
])
export const WEEK = [
	"Sunday",
	"Monday",
	"Tuesday",
	"Wednesday",
	"Thursday",
	"Friday",
	"Saturday",
]
export const WEEKShow = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
export const TIME_HOUR = [1, 2, 3, 4, 5, 6, 7, 8, 9]
export const NUMBER_OF_SHARE = [1, 2, 3, 4, 5, 6, 7, 8, 9]
export const TIME = TIME_HOUR.map(i => i * 60 * 60 * 1000)
export const FACILITY_TYPE = new Map([
	[1, "Common area"],
	[2, "Unit cluster"],
])
