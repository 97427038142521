/*
 * @Author: yanlin.shen
 * @Email: yanlin.shen@chinatalentgroup.com
 * @Date: 2020-09-16 09:22:42
 * @LastAuthor: yanlin.shen
 * @LastTime: 2020-09-18 15:04:46
 * @message:
 */
import { parse } from "qs"
// import modelExtend from 'dva-model-extend'
import api from "api"
import modelExtend from "dva-model-extend"
import moment from "moment"
import { model } from "utils/model"
import { history } from "umi"
import { message } from "antd"
import store from "store"
import { isEmpty } from "lodash"
import { WEEK } from "utils/constant"
import { pathToRegexp } from "path-to-regexp"

const {
	getVenueList,
	getEstate,
	getVenuesBookingList,
	venue,
	editVenue,
	delVenue,
	getFacility,
} = api
const getOpening = list => {
	return list.map(i => {
		const weekList = (i.week_slots && i.week_slots.map(week => week.week)) || []
		const starttimeList =
			(i.week_slots && i.week_slots.map(week => week.slot_start)) || []
		const endtimeList =
			(i.week_slots && i.week_slots.map(week => week.slot_end)) || []
		const weekMax = Math.max.apply(null, weekList)
		const weekMin = Math.min.apply(null, weekList)
		const minTime = Math.min.apply(null, starttimeList)
		const maxTime = Math.max.apply(null, endtimeList)
		return {
			...i,
			weekMax: isNaN(0 * weekMax) ? null : weekMax,
			weekMin: isNaN(0 * weekMin) ? null : weekMin,
			minTime: isNaN(0 * minTime) ? null : minTime,
			maxTime: isNaN(0 * maxTime) ? null : maxTime,
		}
	})
}
export default modelExtend(model, {
	namespace: "venues",
	state: {
		tableList: [],
		timeSlotList: [],
		limit: 10,
		from: 0,
		total: 0,
	},
	subscriptions: {
		setup({ dispatch, history }) {
			history.listen(({ pathname }) => {
				if (pathToRegexp("/apps/venues").exec(pathname)) {
					dispatch({ type: "app/getEstate" }).then(() => {
						dispatch({ type: "query" })
					})
				}
			})
		},
	},
	effects: {
		*query({ payload }, { call, put, select }) {
			const { selectedEstate } = yield select(_ => _.app)
			const params = { estate_id: selectedEstate.estate_id }
			const { estate_id } = params
			const { limit, from } = payload ? payload : yield select(_ => _.venues)
			if (payload) {
				yield put({
					type: "updateState",
					payload: { limit, from },
				})
			}
			const data = yield call(getVenueList, { estate_id, limit, from }) || {}
			if (data && data.success) {
				yield put({
					type: "updateState",
					payload: {
						tableList: getOpening(data.data.list),
						total: data.data.total,
					},
				})
				yield put({
					type: "getFacilities",
				})
				yield put({
					type: "bookings/getTimeSlot",
				})
			}
		},
		*getFacilities({ payload }, { call, select, put }) {
			const { selectedEstate = {} } = yield select(_ => _.app) || {}
			const { estate_id } = selectedEstate
			const res = yield call(getFacility, { estate_id, building_type: 1 }) || {}
			if (!res) return
			const { data } = res
			if (res && data) {
				yield put({
					type: "updateState",
					payload: { facilityList: data.list },
				})
			}
		},
		*bookingOrView({ payload }, { put }) {
			const { item, venue_id, date } = payload
			const params = {
				week_slot_id: item.id,
				venue_id,
				year: date.year(),
				month: date.month() - 0 + 1,
				day: date.date(),
				user_name: (store.get("user") || {}).full_name,
				remarks: item.remarks,
			}
			yield put({ type: "venues/updateState", payload: { params, payload } })
			history.push("/bookings?add=true")
		},
		*del({ payload }, { call, put }) {
			const data = yield call(delVenue, payload)
			if (data && data.success) {
				yield put({ type: "query" })
				return data
			} else {
				message.error((data && data.message) || "Error!")
			}
		},

		*add({ payload }, { call, put, select }) {
			const { selectedEstate = {} } = yield select(_ => _.app) || {}
			const estate_id = selectedEstate.estate_id || 1
			const {
				booking_before,
				cancel_before,
				facility_name,
				deposit,
				facility_id,
				fee,
				max_per_slot,
				max_per_user,
				booking_type,
				break_duration,
				pay_after,
				slot_duration,
				week_slot: weekObj,
				edit,
				time_period,
				venue_id,
			} = payload
			const { timeList: timeObjList = [] } = yield select(_ => _.bookings)
			// let week_slot = []
			// const slot_duration = parseInt(duration)
			// for (let key in weekObj) {
			// 	if (weekObj[key] && weekObj[key].checked) {
			// 		const { slot_end: end, slot_start: start } = weekObj[key]

			// 		if (!slot_duration || !(start !== 0 && start) || !end) {
			// 			message.error("error information")
			// 			return
			// 		}
			// 		let slot_start = start
			// 		const timeList = Math.floor((end - start) / (slot_duration - 0))
			// 		for (let i = 0; i < timeList; i++) {
			// 			const { id: slot_id } =
			// 				timeObjList.find(i => i.start === slot_start) || {}
			// 			const obj = {
			// 				week: key,
			// 				slot_start,
			// 				slot_id,
			// 				slot_end: slot_start + (slot_duration - 0),
			// 			}
			// 			slot_start += +slot_duration
			// 			week_slot.push(obj)
			// 		}
			// 	}
			// }
			const TimeMap = new Map([])
			timeObjList.forEach(time => {
				if (isEmpty(time)) return
				TimeMap.set(time.start, time.id)
			})
			const week_slot = []
			WEEK.forEach((item, index) => {
				if (weekObj[index] && weekObj[index].checked) {
					week_slot.push({
						week: index,
						slot_id_from: TimeMap.get(weekObj[index].slot_start),
						slot_id_to: TimeMap.get(weekObj[index].slot_end),
					})
				}
			})
			const params = {
				booking_before: (booking_before - 0) * 3600 * 1000 * 24,
				cancel_before: (cancel_before - 0) * 3600 * 1000,
				facility_id,
				time_period,
				break_duration:
					parseInt(break_duration) !== 0
						? parseInt(break_duration) < 10
							? parseInt(break_duration) * 3600 * 1000
							: parseInt(break_duration)
						: 0,
				booking_type,
				deposit: Math.floor(Number(deposit - 0) * 100),
				fee: Math.floor(Number(fee - 0) * 100),
				max_per_slot: max_per_slot - 0,
				max_per_user: max_per_user - 0,
				pay_after: (pay_after - 0) * 3600 * 1000,
				estate_id,
				slot_duration:
					parseInt(slot_duration) !== 1
						? parseInt(slot_duration) < 10
							? parseInt(slot_duration) * 3600 * 1000
							: parseInt(slot_duration)
						: 3600 * 1000,
				week_slot,
				facility_name,
			}
			if (params.max_per_user < 1) {
				message.error("Concurrent bookings value must be greater or equal to 1")
				return
			}
			// if (params.fee < 1) {
			// 	message.error('Fee\'s value must be greater or equal to 1');
			// 	return
			// }
			// if (params.deposit < 1) {
			// 	message.error('Deposit\'s value must be greater or equal to 1');
			// 	return
			// }
			if (params.slot_duration <= 0) {
				message.error("Bookings per slot field is required")
				return
			}
			if (params.booking_before <= 0) {
				message.error("Booking window value must be greater or equal to 1")
				return
			}
			if (!params.week_slot.length) {
				message.error("Opening hours field is required.")
				return
			}
			const data = yield call(
				edit ? editVenue : venue,
				edit ? { ...params, venue_id } : params,
			)
			if (data && data.success) {
				yield put({
					type: "venues/query",
				})
				return data
			}
			return undefined
		},

		*bookingStatus({ payload }, { call, select, put }) {
			const { date: params, venue_id } = payload
			const date = moment(params)
			const day = date.date()
			const month = date.month() + 1
			const year = date.year()
			const data = yield call(getVenuesBookingList, {
				venue_id,
				year,
				month,
				day,
			}) || {}
			if (data && data.success) {
				const { list: timeSlotList = [] } = data.data || {}
				yield put({
					type: "updateState",
					payload: { timeSlotList },
				})
			}
		},
	},
})
