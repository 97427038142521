/*
 * @Author: yanlin.shen
 * @Email: yanlin.shen@chinatalentgroup.com
 * @Date: 2020-09-14 09:21:51
 * @LastAuthor: yanlin.shen
 * @LastTime: 2020-09-18 14:07:32
 * @message:
 */
import { history } from "umi"
import api from "api"
import store from "store"
import { routeList } from "./route"
import { routeList as resident_route } from "./resident_route"
import { message } from "antd"
import { pathToRegexp } from "path-to-regexp"

const { loginUser, resetEmail, confirmCode } = api

export default {
	namespace: "login",

	state: {
		resetStatus: false,
		confirmStatus: false,
	},
	subscriptions: {
		setup({ dispatch, history }) {
			history.listen(location => {
				if (pathToRegexp("/login").exec(location.pathname)) {
					const { estate_id: initEstateId } = location.query
					if (initEstateId) {
						dispatch({
							type: "app/updateState",
							payload: { initEstateId },
						})
					}
					dispatch({
						type: "app/query",
					})
				}
			})
		},
	},
	reducers: {
		resetEmailStatus(state, { payload }) {
			return {
				...state,
				...payload,
			}
		},
		comfirmCodeStatus(state, { payload }) {
			return {
				...state,
				...payload,
			}
		},
	},
	effects: {
		*login({ payload }, { put, call, select }) {
			const data = yield call(loginUser, payload)
			// const { locationQuery } = yield select(_ => _.app)
			if (data && data.success) {
				if (!data.data.dashboard_enabled) {
					// message.error("You have no permission to view the dashboard")
					//FIXME:need confirm
					// history.push("/login")
					// return
					store.set("resident", true)
				}
				store.set("token", data.data.token)
				store.set("user", { ...(data || {}).data })
				// store.set(
				// 	"routeList",
				// 	data.data.dashboard_enabled ? routeList : resident_route,
				// )
				yield put({ type: "app/query" })

				// if (!pathToRegexp('/login').exec(from)) {
				//   if (['', '/'].includes(from)) history.push('/dashboard')
				//   else history.push(from)
				// } else {
				//   history.push('/dashboard')
				// }
			}
		},
		*reset({ payload }, { call, put }) {
			// const {email} = payload;
			const data = yield call(resetEmail, payload)
			if (data && data.success) {
				yield put({ type: "resetEmailStatus", payload: { resetStatus: true } })
			}
		},
		*confirm({ payload }, { call, put, select }) {
			const { redeem } = yield select(_ => _.app)
			const { invitationCode } = payload
			yield put({ type: "resetEmailStatus", payload: { invitationCode } })
			const data = yield call(confirmCode, { code: invitationCode })

			if (data && data.success) {
				const now = new Date().valueOf()
				const { expired_at, email, to_user_id, unit } = data.data || {}
				if (expired_at > now && to_user_id - 0 === 0) {
					yield put({
						type: "app/updateState",
						payload: {
							email,
							unit,
						},
					})
					history.push("/register")
				} else {
					yield put({
						type: "resetEmailStatus",
						payload: { invitationCode: undefined },
					})
					expired_at < now && message.error("The code expired.")
					to_user_id - 0 !== 0 && message.error("The user has been signed up.")
					yield put({
						type: "app/updateState",
						payload: {
							code: undefined,
							email: undefined,
							redeem: undefined,
							unit: undefined,
						},
					})
					history.push("/login")
				}
			} else {
				yield put({
					type: "app/updateState",
					payload: {
						code: undefined,
						email: undefined,
						redeem: undefined,
						unit: undefined,
					},
				})
				store.clearAll()
				history.push("/login")
			}
			return data || {}
		},
	},
}
