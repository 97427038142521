/* eslint-disable import/no-anonymous-default-export */
export default {
	queryRouteList: "/routes",

	queryUserInfo: "/user",
	logoutUser: "/user/logout",
	queryUser: "/user/:id",
	queryUserList: "/users",
	updateUser: "Patch /user/:id",
	// confirmCode: "POST /confirm/code",
	createUser: "POST /user",
	removeUser: "DELETE /user/:id",
	removeUserList: "POST /users/delete",

	queryPostList: "/posts",
	getUser: "/remote/user/users-by-estate",
	queryDashboard: "/remote/estate/stat",
	loginUser: "POST /remote/auth/login",
	header: { getEstate: "/remote/user/estates" },
	bookings: {
		getTimeSlots: "/remote/booking/list-by-admin",
		getFacility: "/remote/estate/facilities",
	},
	addFacility: "POST /remote/estate/facility",
	editFacility: "PATCH /remote/estate/facility",
	register: {
		signUpUrl: "POST /remote/auth/register",
	},
	getVenueList: "/remote/venue/list",
	venue: "POST /remote/venue",
	addBookings: "POST /remote/booking",
	getOwner: "/remote/user/invitation-code",
	sendEmail: "POST /remote/user/invitation-code",
	timeSlot: "/remote/common/time-slots",
	linkRecord: "/remote/user/invitation-codes-by-estate",
	getVenuesBookingList: "/remote/venue/week-slots",
	bookingStatus: "PATCH /remote/booking/status",
	userApi: "PATCH /remote/user/estate",
	delEmail: "DELETE /remote/user/invitation-code",
	delFacility: "DELETE /remote/estate/facility",
	resetEmail: "POST /remote/common/password-missing",
	confirmCode: "/remote/common/invitation-code",
	resetPwd: "POST /remote/common/password-reset",
	bookingPaymentStatus: "PATCH /remote/booking/payment-status",
	editVenue: "PATCH /remote/venue",
	editTenant: "PATCH /remote/user/invitation-code-name",
	editSelfAPI: "PATCH /remote/user/self-info",
	delVenue: "DELETE /remote/venue",
	userBooking: "/remote/booking/list-by-user",
	cancelBookingByUser: "PATCH /remote/booking/canceled-status",
	gethelpUrl: "POST /remote/booking/booking-help",
	getUnit: "/remote/unit/list",
	addUnit: "post /remote/unit",
	delUnit: "delete /remote/unit",
	editUnit: "patch /remote/unit",
	sendEmailUrl: "post /remote/booking/receipt-email",
	setRemarkUrl: "patch /remote/booking/remarks",
	bookingDetailUrl: "/remote/booking/detail-by-user",
	getAssetList: "/remote/asset/list",
	getSignatureUrl: "/remote/s3",
	addAssetsUrl: "POST /remote/asset",
	editAssetsUrl: "patch /remote/asset",
	assetFileUrl: "/remote/asset_file/list",
	delAssetUrl: "delete /remote/asset",
	vehicleApi: "/remote/vehicle/list",
	addVehi: "post /remote/vehicle",
	editVehi: "patch /remote/vehicle",
	delVehi: "DELETE /remote/vehicle",
	accessApi: "/remote/access_card/list",
	addAcc: "post /remote/access_card",
	delAcc: "DELETE /remote/access_card",
	addFileDocUrl: "post /remote/asset_file",
	paymentList: "/remote/payment/list",
	payment: "post /remote/payment",
	workOrder: "/remote/work_order/list",
	addOrder: "post /remote/work_order",
	editOrder: "PATCH /remote/work_order",
	getWorkOrderFile: "/remote/work_order_file/list",
	getContractor: "/remote/contractor/list",
	createContractor: "post /remote/contractor",
	delOrder: "DELETE /remote/work_order",
	editContractor: "patch /remote/contractor",
	delContractor: "Delete /remote/contractor",
	getCoordinator: "/remote/contractor/detail",
	workOrderItem: "/remote/work_order",
	getTimeLine: "/remote/work_order_timeline/list",
	postComment: "post /remote/work_order_timeline/remarks",
	delCommentApi: "delete /remote/work_order_timeline/remarks",
	workerList: "/remote/work_order/worker/list",
	// upLoadFile: "POST /remote/work_order_file",
	updateWorkOrderFile: "POST /remote/work_order_file",
	deleteWorkOrderFile: "delete /remote/work_order_file",
	editWorkOrderFile:'patch /remote/work_order_file',
}
