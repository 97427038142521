/*
 * @Author: your name
 * @Date: 2021-06-06 20:56:12
 * @LastEditTime: 2021-09-08 17:26:51
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \basement\basementWeb\src\models\payment.tsx
 */
import { useState, useEffect, useCallback } from 'react';
export default function Receipt() {
  const [record, setRecord] = useState<POJO>({})
  return { record, setRecord }
}