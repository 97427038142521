/*
 * @Author: your name
 * @Date: 2021-06-06 20:56:12
 * @LastEditTime: 2021-09-14 00:03:54
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \basement\basementWeb\src\models\payment.tsx
 */
import { useState, useEffect } from "react"
import API from "@/services"
import { AxiosRequestConfig } from "axios"
export const DEFAULT_LIMIT=50
let timer
const debounce = (fn, time = 1000) => {
	// let timer
	return (...args) => {
		// console.log(args,fn)
		// if(!timer){
		//   fn.apply(this, args);
		// }
		clearTimeout(timer)
		timer = setTimeout(() => {
			fn.apply(this, args)
		}, time)
	}
}
export default function useMaintenance() {
	const [pagnation, setPagnation] = useState({ limit: DEFAULT_LIMIT, from: 0 })
	const [total, setTotal] = useState(0)
	const [order_by, setOrder_by] = useState<string>("created_at")
	const [estate_id, setEstateId] = useState()
	const [asc, setAsc] = useState(2)
	const [facility_id, setFacility_id] = useState(-1)
	// const [user_name, setUser_name] = useState('');
	const [time_start, setTime_start] = useState() //moment().startOf('day').unix())
	const [time_end, setTime_end] = useState() //moment().endOf('day').unix())
	const [status, setStatus] = useState("")
	const getItem = async (id: string) => {
		setLoading(true)
		const data = await API.workOrderItem({ id })
		return data?.data || {}
	}
	const request = (
		data: any,
		options?: Omit<AxiosRequestConfig, "url" | "method" | "data">,
	) => {
		setLoading(true)
		return API.workOrder(data, options)
	}
	const getData = async () =>
		request({
			...pagnation,
			order_by,
			asc,
			facility_id,
			estate_id,
			start: time_start,
			end: time_end,
			status, //user_name,
			dump_file: 0,
		})
			.then(data => {
				const list = data?.data?.list || []
				const total = data?.data?.total || 0
				setDataList(list)
				setLoading(false)
				// setPagnation({ ...pagnation, })
				setTotal(total)
			})
			.finally(() => setLoading(false))
	const exportCSV = async () => {
		request(
			{
				limit: 500,
				from: -1,
				order_by,
				asc,
				facility_id,
				estate_id,
				start: time_start,
				end: time_end,
				status, //user_name,
				dump_file: 1,
			},
			{
				responseType: "blob",
			},
		)
			.then(response => {
				setLoading(false)
				// link.href =
				const url = window.URL.createObjectURL(
					new Blob([response?.response?.data || ""], { type: "text/csv" }),
				)
				// window.open(url)
				const a = document.createElement("a")
				a.download = `${require("uuid").v4()}.csv`
				a.href = url
				a.click()
			})
			.finally(() => setLoading(false))
	}
	const [dataList, setDataList] = useState([])
	const [loading, setLoading] = useState(false)
	useEffect(() => {
		setPagnation({ limit: DEFAULT_LIMIT, from: 0 })
	}, [status, time_end, time_start])

	useEffect(() => {
		estate_id && getData()
	}, [
		pagnation,
		order_by,
		asc,
		time_start,
		time_end,
		status,
		facility_id,
		estate_id,
	])
	useEffect(() => {
		estate_id && order_by && debounce(getData)()
	}, [order_by])

	// ((account, password) => {
	//   request({
	//     ...pagnation,order_by,asc,userId
	//   })
	// }, [])
	const handleAdd = async value => {
		setLoading(true)
		const data = await API.addOrder(value)
		getData()
		return data
	}
	const handleDel = async work_order_id => {
		setLoading(true)
		await API.delOrder({ work_order_id })
		getData()
	}
	const handleEdit = async (value: any, noRefresh?: boolean) => {
		!noRefresh && setLoading(true)
		const response = await API.editOrder({ ...value })
		!noRefresh && getFile(value?.work_order_id)
		if (!noRefresh) {
			getData()
		}
		return response
	}
	const getFile = async work_order_id => {
		// setLoading(true)
		const res = await API.getWorkOrderFile({ work_order_id })
		// setLoading(false)
		return res
	}
	const updateFile = async value => {
		setLoading(true)
		// delete value.files
		await API.updateWorkOrderFile(value)
		await getFile(value?.work_order_id)
		getData()
	}
	const deleteFile = async value => {
		setLoading(true)
		// delete value.files
		await API.deleteWorkOrderFile({ work_order_file_id: value?.id })
		await getFile(value?.work_order_id)
		getData()
	}
	const editFile = async value => {
		setLoading(true)
		// delete value.files
		await API.editWorkOrderFile({ ...value, work_order_file_id: value?.id })
		await getFile(value?.work_order_id)
		getData()
	}
	const postComment = async (id: number, content: string) => {
		await API.postComment({ work_order_id: id, content })
	}
	const getTimeLine = async (payload: {
		work_order_id: any
		limit: number
		from: number
	}) => {
		const data = await API.getTimeLine(payload)
		return data
	}
	const delComment = async payload => {
		const data = await API.delCommentApi(payload)
		return data
	}
	const getWorkerList = async work_order_id => {
		const data = await API.workerList({ work_order_id })
		return data || {}
	}
	return {
		getData,
		getTimeLine,
		pagnation,
		setPagnation,
		order_by,
		setOrder_by,
		setFacility_id,
		asc,
		setAsc,
		dataList,
		time_start,
		setTime_start,
		time_end,
		setTime_end,
		status,
		setStatus,
		loading,
		total,
		setEstateId, //,user_name, setUser_name,
		handleAdd,
		getFile,
		handleEdit,
		updateFile,
		handleDel,
		getItem,
		postComment,
		delComment,
		getWorkerList,
		setLoading,
		deleteFile,
		editFile,
		exportCSV,
	}
}
