/*
 * @Author: your name
 * @Date: 2020-09-17 20:54:10
 * @LastEditTime: 2020-10-26 01:30:54
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /basement/basementWeb/src/components/Drawer/index.js
 */
import React, { useState, useEffect } from "react"
import { Drawer } from "antd"
import { enquireScreen, unenquireScreen } from "enquire-js"
export default props => {
	const { width, children, disRender = false, ...reset } = props
	const [isMobile, setIsMobile] = useState(false)
	useEffect(() => {
		const app = enquireScreen(mobile => {
			if (isMobile !== mobile) {
				setIsMobile(mobile)
			}
			return () => unenquireScreen(app)
		})
	}, [])
	return (
		<Drawer
			destroyOnClose={!disRender}
			closeIcon={<img src={require("../../../public/close_drawer_icon.png")} />}
			// className={styles.drawer_width}
			width={width || isMobile ? "100%" : "40%"}
			{...reset}
		>
			<React.Fragment>{children}</React.Fragment>
		</Drawer>
	)
}
