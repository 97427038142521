/*
 * @Author: yanlin.shen
 * @Email: yanlin.shen@chinatalentgroup.com
 * @Date: 2020-09-11 09:39:01
 * @LastAuthor: yanlin.shen
 * @LastTime: 2020-09-23 13:44:47
 * @message:
 */
import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { Switch, Layout } from "antd"
import { t, Trans } from "@lingui/macro"
import { BulbOutlined } from "@ant-design/icons"
import ScrollBar from "../ScrollBar"
import { config } from "utils"
import SiderMenu from "./Menu"
import styles from "./Sider.less"

class Sider extends PureComponent {
	render() {
		const {
			menus,
			theme,
			isMobile,
			collapsed,
			onThemeChange,
			onCollapseChange,
		} = this.props

		return (
			<Layout.Sider
				width={!isMobile ? 256 : "100%"}
				theme={theme}
				breakpoint="lg"
				trigger={null}
				collapsible
				collapsed={collapsed}
				onBreakpoint={!isMobile ? onCollapseChange : () => {}}
				className={`${styles.sider} ${isMobile ? styles.mobile : ""}`}
			>
				<div className={styles.brand}>
					<div className={styles.logo}>
						{!collapsed && <img alt="logo" src={config.logoPath} />}
						{/* {collapsed && <h1>{config.siteName.slice(0, 1)}</h1>} */}
					</div>
				</div>

				<div className={styles.menuContainer}>
					<ScrollBar
						options={{
							// Disabled horizontal scrolling, https://github.com/utatti/perfect-scrollbar#options
							suppressScrollX: true,
						}}
					>
						<SiderMenu
							menus={menus}
							theme={theme}
							isMobile={isMobile}
							collapsed={collapsed}
							onCollapseChange={onCollapseChange}
						/>
					</ScrollBar>
				</div>
				{/* {!collapsed && (
					<div className={styles.switchTheme}>
						<span>
							<BulbOutlined />
							<Trans>Switch Theme</Trans>
						</span>
						<Switch
							onChange={onThemeChange.bind(
								this,
								theme === "dark" ? "light" : "dark",
							)}
							defaultChecked={theme === "dark"}
							checkedChildren={i18n.t`Dark`}
							unCheckedChildren={i18n.t`Light`}
						/>
					</div>
				)} */}
			</Layout.Sider>
		)
	}
}

Sider.propTypes = {
	menus: PropTypes.array,
	theme: PropTypes.string,
	isMobile: PropTypes.bool,
	collapsed: PropTypes.bool,
	onThemeChange: PropTypes.func,
	onCollapseChange: PropTypes.func,
}

export default Sider
