/*
 * @Author: your name
 * @Date: 2020-09-17 20:54:10
 * @LastEditTime: 2021-07-22 18:31:58
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /basement/basementWeb/src/pages/register/model.js
 */
import { history } from "umi"
import api from "api"
import { message } from "antd"
import modelExtend from "dva-model-extend"
import { model } from "utils/model"
import { pathToRegexp } from "path-to-regexp"

const { resetPwd } = api

export default modelExtend(model, {
	namespace: "resetPassword",

	state: { email: "", secret: "" },
	subscriptions: {
		setup({ dispatch, history }) {
			history.listen(location => {
				if (pathToRegexp("/resetPassword").exec(location.pathname)) {
					const { email, secret } = location.query || {}
					dispatch({
						type: "updateState",
						payload: {
							email,
							secret,
						},
					})
				}
			})
		},
	},
	reducers: {},
	effects: {
		*reset({ payload }, { select, call, put }) {
			const { email, secret } = yield select(_ => _.resetPassword)
			const params = {
				email,
				secret,
				new_password: payload.new_password,
			}
			const data = yield call(resetPwd, params)
			if (data && data.success) {
				message.success("Success!")
				yield put({
					type: "login/login",
					payload: {
						email: params.email,
						password: params.new_password,
					},
				})
				yield put({
					type: "updateState",
					payload: {
						email: "",
						secret: "",
					},
				})
			}
		},
	},
})
