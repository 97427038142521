import React, { PureComponent, Fragment } from "react"
import PropTypes from "prop-types"
import { Menu, Layout, Avatar, Popover, Badge, List, Select } from "antd"
import { Ellipsis } from "components"
import { Icon as LegacyIcon } from "@ant-design/compatible"
import {
	BellOutlined,
	RightOutlined,
	EditOutlined,
	ExportOutlined,
} from "@ant-design/icons"
import { Trans } from "@lingui/macro"
// import { setLocale } from "utils"
import { history } from "umi"
import moment from "moment"
import classnames from "classnames"
import config from "config"
import store from "store"
import { getName, getLocale, setLocale } from "utils"
import styles from "./Header.less"

const { SubMenu } = Menu
const { Option } = Select
const NewButton = () => {
	return (
		<Select
			onSelect={val => {
				if (val === "2") {
					history.push("/apps/bookings?add=1")
				}
				if (val === "1") {
					history.push("/work-orders?add=1")
				}
				setTimeout(() => {
					window.history.replaceState(
						null,
						document.title,
						window.location.pathname.split("?")[0],
					)
				}, 50)
			}}
			showArrow={false}
			getPopupContainer={node => node}
			value="+ New"
			className={styles.new_btn}
			dropdownStyle={{ minWidth: "200px" }}
		>
			<Option value="1">Work Order</Option>
			<Option value="2">Booking</Option>
		</Select>
	)
}
class Header extends PureComponent {
	handleClickMenu = e => {
		e.key === "SignOut" && this.props.onSignOut()
		e.key === "EditUser" && this.props.editSelf() //history.push("/tenants?self=1")
	}

	render() {
		const {
			fixed,
			// avatar,
			user: userInfo,
			collapsed,
			notifications,
			estateList = [],
			isMobile,
			onSelectChange,
			onCollapseChange,
			onAllNotificationsRead,
			selectedEstate = {},
		} = this.props
		const { full_name: username = "" } = userInfo
		// console.log("Header -> render -> userInfo", userInfo)

		const rightContent = [
			<Menu key="user" mode="horizontal" onClick={this.handleClickMenu}>
				<SubMenu
					title={
						<Fragment>
							{/* <span style={{ color: "#999", marginRight: 4 }}>
								<Trans>Hi,</Trans>
							</span>
							<span>{username}</span> */}
							{/* <Avatar style={{ marginLeft: 8 }} src={avatar} /> */}
							<Avatar size={40}>{getName(username)}</Avatar>
						</Fragment>
					}
				>
					<Menu.Item key="EditUser">
						<Trans>
							<EditOutlined />
							Edit profile
						</Trans>
					</Menu.Item>
					<Menu.Item key="SignOut">
						<Trans>
							<ExportOutlined />
							Logout
						</Trans>
					</Menu.Item>
				</SubMenu>
			</Menu>,
		]
		//translate
		// if (config.i18n) {
		//   const { languages } = config.i18n
		//   const currentLanguage = languages.find(
		//     item => item.key === i18n._language
		//   )

		//   rightContent.unshift(
		//     <Menu
		//       key="language"
		//       selectedKeys={[currentLanguage.key]}
		//       onClick={data => {
		//         setLocale(data.key)
		//       }}
		//       mode="horizontal"
		//     >
		//       <SubMenu title={<Avatar size="small" src={currentLanguage.flag} />}>
		//         {languages.map(item => (
		//           <Menu.Item key={item.key}>
		//             <Avatar
		//               size="small"
		//               style={{ marginRight: 8 }}
		//               src={item.flag}
		//             />
		//             {item.title}
		//           </Menu.Item>
		//         ))}
		//       </SubMenu>
		//     </Menu>
		//   )
		// }
		rightContent.unshift(<NewButton />)
		rightContent.unshift(
			<Select
				key="estate"
				className={styles.selectEstate}
				labelInValue
				value={{
					value: selectedEstate && selectedEstate.id ? selectedEstate.id : "",
				}}
				onChange={onSelectChange}
			>
				{estateList.map((item, index) => (
					<Option key={`estate-${index}`} value={item.id}>
						{item.estate_name}
					</Option>
				))}
				{/* <Option value='addNew'>+Add new estate</Option> */}
			</Select>,
		)
		//notification
		// rightContent.unshift(
		// 	<Popover
		// 		placement="bottomRight"
		// 		trigger="click"
		// 		key="notifications"
		// 		overlayClassName={styles.notificationPopover}
		// 		getPopupContainer={() => document.querySelector("#primaryLayout")}
		// 		content={
		// 			<div className={styles.notification}>
		// 				<List
		// 					itemLayout="horizontal"
		// 					dataSource={notifications}
		// 					locale={{
		// 						emptyText: <Trans>You have viewed all notifications.</Trans>,
		// 					}}
		// 					renderItem={item => (
		// 						<List.Item className={styles.notificationItem}>
		// 							<List.Item.Meta
		// 								title={
		// 									<Ellipsis tooltip lines={1}>
		// 										{item.title}
		// 									</Ellipsis>
		// 								}
		// 								description={moment(item.date).fromNow()}
		// 							/>
		// 							<RightOutlined style={{ fontSize: 10, color: "#ccc" }} />
		// 						</List.Item>
		// 					)}
		// 				/>
		// 				{notifications.length ? (
		// 					<div
		// 						onClick={onAllNotificationsRead}
		// 						className={styles.clearButton}
		// 					>
		// 						<Trans>Clear notifications</Trans>
		// 					</div>
		// 				) : null}
		// 			</div>
		// 		}
		// 	>
		// 		<Badge
		// 			count={notifications.length}
		// 			dot
		// 			offset={[-10, 10]}
		// 			className={styles.iconButton}
		// 		>
		// 			<BellOutlined className={styles.iconFont} />
		// 		</Badge>
		// 	</Popover>,
		// )
		const title = () => {
			const routList = (history.location.pathname || "").split("/") || []
			const name = (!routList.length
				? ""
				: routList[routList.length - 1]
			).replaceAll("-", " ")

			// console.log(history, routList, name)
			return (
				<div className={styles.title} style={{ textTransform: "capitalize" }}>
					{name}
				</div>
			)
		}
		return (
			<Layout.Header
				className={classnames(styles.header, {
					[styles.fixed]: fixed,
					[styles.collapsed]: collapsed,
				})}
				id="layoutHeader"
			>
				{/* {!store.get("resident") && ( */}
				<div
					className={styles.button}
					onClick={onCollapseChange.bind(this, !collapsed)}
				>
					<LegacyIcon
						component={() => <img src={config.menuLogo} />}
						// type={classnames({
						// 	"menu-unfold": collapsed,
						// 	"menu-fold": !collapsed,
						// })}
					/>
				</div>
				{/* )} */}
				{!isMobile && title()}

				<div className={styles.rightContainer}>{rightContent}</div>
			</Layout.Header>
		)
	}
}

Header.propTypes = {
	fixed: PropTypes.bool,
	user: PropTypes.object,
	menus: PropTypes.array,
	collapsed: PropTypes.bool,
	onSignOut: PropTypes.func,
	notifications: PropTypes.array,
	onCollapseChange: PropTypes.func,
	onAllNotificationsRead: PropTypes.func,
}

export default Header
