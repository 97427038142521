/*
 * @Author: yanlin.shen
 * @Email: yanlin.shen@chinatalentgroup.com
 * @Date: 2020-09-17 10:49:04
 * @LastAuthor: yanlin.shen
 * @LastTime: 2020-09-22 10:43:17
 * @message:
 */
import { parse } from "qs"
import modelExtend from "dva-model-extend"
import api from "api"
import { message } from "antd"
import store from "store"
import { model } from "utils/model"
import { ROLE_TYPE } from "utils/constant"
import { pathToRegexp } from "path-to-regexp"

const { queryDashboard, getEstate, delEmail, sendEmail, linkRecord } = api
const titleMap = new Map([
	// ["resident_cnt", "Occupants"],
	// ["completed_cnt", "Completed Bookings"],
	// ["pending_cnt", "Pending Bookings"],
	["work_order_holding_cnt", "On hold"],
	["work_order_open_cnt", "Open"],
	["work_order_inprogress_cnt", "In progress"],
])

export default modelExtend(model, {
	namespace: "dashboard",
	state: {
		userInfoList: [],
		teamMemberList: [],
		quote: {
			avatar:
				"http://img.hb.aicdn.com/bc442cf0cc6f7940dcc567e465048d1a8d634493198c4-sPx5BR_fw236",
		},
		numbers: [],
	},
	subscriptions: {
		setup({ dispatch, history }) {
			history.listen(({ pathname }) => {
				if (
					pathToRegexp("/dashboard").exec(pathname) ||
					pathToRegexp("/").exec(pathname)
				) {
					dispatch({ type: "app/getEstate" }).then(() =>
						dispatch({ type: "query" }),
					)
				}
			})
		},
	},
	effects: {
		*query({ payload }, { call, put, select }) {
			const { selectedEstate } = yield select(_ => _.app)
			const params = { estate_id: selectedEstate.estate_id }
			// //刷新
			// if (!selectedEstate.estate_id) {
			// 	const estate = yield call(getEstate) || {}
			// 	if (estate && estate.success) {
			// 		yield put({
			// 			type: "app/updateState",
			// 			payload: { estateList: (estate.data && estate.data.list) || [] },
			// 		})
			// 		const payload =
			// 			estate.data && initEstateId
			// 				? estate.data.list.find(i => i.estate_id === initEstateId) || {}
			// 				: estate.data.list[0]
			// 		yield put({ type: "app/selectedEstate", payload })
			// 		const { estate_id = 1 } = payload
			// 		params = { estate_id }
			// 	}
			// } else {
			// 	params = { estate_id: selectedEstate.estate_id }
			// }
			const res = yield call(queryDashboard, { ...params }) || {}
			if (res) {
				const { data } = res
				let numbers = []
				if (res && res.success && data) {
					for (let key in data) {
						titleMap.get(key)&&numbers.push({ title: titleMap.get(key), number: data[key] })
					}
				}
				yield put({
					type: "updateState",
					payload: { numbers },
				})
			}

			const userData = yield call(linkRecord, {
				...params,
				role: `${ROLE_TYPE.Administrator},${ROLE_TYPE.Security},${ROLE_TYPE.Owner},${ROLE_TYPE["Limited Member"]}`,
				to_user_id: -1,
				...payload,
			}) || {}
			if (userData && userData.success) {
				const { list: userInfoList } = userData.data || {}
				// const user = store.get('user')
				// const selfUser = {
				// 	name: user.full_name,
				// 	role: user.dashboard_enabled ? 2 : 1,
				// 	id: user.user_id,
				// 	to_user_id: true,
				// 	email: user.identifier
				// }
				// console.log(store.get('user'))
				if (Array.isArray(userInfoList)) {
					yield put({
						type: "updateState",
						payload: {
							userInfoList: [...userInfoList],
						},
					})
				}
			}
		},
		*queryTeamMember({ payload }, { call, put, select }) {
			const { selectedEstate } = yield select(_ => _.app)
			const params = { estate_id: selectedEstate.estate_id }
			const userData = yield call(linkRecord, {
				...params,
				role: `${ROLE_TYPE.Administrator},${ROLE_TYPE.Security},${ROLE_TYPE.Owner},${ROLE_TYPE["Limited Member"]}`,
				to_user_id: -1,
				...payload,
			}) || {}
			if (userData && userData.success) {
				const { list: teamMemberList } = userData.data || {}
				// const user = store.get('user')
				// const selfUser = {
				// 	name: user.full_name,
				// 	role: user.dashboard_enabled ? 2 : 1,
				// 	id: user.user_id,
				// 	to_user_id: true,
				// 	email: user.identifier
				// }
				// console.log(store.get('user'))
				if (Array.isArray(teamMemberList)) {
					yield put({
						type: "updateState",
						payload: {
							teamMemberList: [...teamMemberList],
						},
					})
				}
			}
		},
		*addTeamMember({ payload }, { call, select, put }) {
			const { selectedEstate } = yield select(_ => _.app)
			const { estate_id } = selectedEstate
			const data = yield call(sendEmail, { ...payload, estate_id }) || {}
			if (data && data.success) {
				// message.success("添加成功");
				yield put({ type: "query" })
				return data
			}
		},
		*removeTeamMember({ payload }, { call, put }) {
			// const { selectedEstate } = yield select(_ => _.app);
			// const {estate_id} = selectedEstate
			const data = yield call(delEmail, { ...payload }) || {}
			if (data && data.success) {
				// message.success("添加成功");
				yield put({ type: "query" })
				return data
			}
		},
		*getLinkRecord({ payload }, { call, select, put }) {
			// const {selectedEstate} = yield select(_=>_.app);
			// const {estate_id } = selectedEstate;
			// const {data} = yield call(linkRecord,{estate_id,role:2});
			//TODO:确认接口user  是否id字段匹配
		},
	},
})
